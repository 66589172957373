/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Container } from './styles';
import PromotionActionGiftForm from './Form';
import PromotionActionsGiftsList from './List';
// eslint-disable-next-line react/prop-types
function ToastAction({ draft, updateDraft, product }) {
  const [list, setList] = useState([]);

  const loadingData = useCallback(async () => {
    // eslint-disable-next-line react/prop-types
    if (draft.data.promotion.brindes) {
      // eslint-disable-next-line react/prop-types
      setList(draft.data.promotion.brindes);
    }
  }, [draft.data.promotion.brindes]);

  useEffect(() => {
    loadingData();
  }, [draft.data.promotion.brindes, loadingData]);

  return (
    <Container>
      <Grid container spacing={2}>
        {draft.status !== 'approved' && (
          <Grid item xs={12} md={6}>
            <PromotionActionGiftForm
              draft={draft}
              loadingData={loadingData}
              updateDraft={updateDraft}
              product={product}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <PromotionActionsGiftsList
            draft={draft}
            updateDraft={updateDraft}
            list={list}
            loadingData={loadingData}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

ToastAction.defaultProps = {
  draft: {},
};

ToastAction.propTypes = {
  draft: PropTypes.objectOf,
};

export default ToastAction;
