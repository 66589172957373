/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import { MdDelete } from 'react-icons/md';
import Img from 'react-image';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import { decimalFormat } from '~/helpers/number';
import { hasPermission } from '~/components/AccessControl';
import api from '~/services/api';
import ItemTypes from './ItemTypes';
import { UploadFile } from './styles';

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};

const Card = ({
  id,
  raffle,
  moveCard,
  findCard,
  onDrop,
  loading,
  draft,
  onDelete,
  raffleType,
  updateDraft,
}) => {
  const originalIndex = findCard(id).index;
  const [loadingFile, setLoadingFile] = React.useState(false);
  const product = useSelector(({ user }) => user.product);
  const [fileName, setFileName] = React.useState(() => {
    let initialState = '';
    if (raffle.fileName) {
      initialState = raffle.fileName;
    }
    return initialState;
  });
  const [{ isDragging }, drag] = useDrag({
    canDrag: () => !loading,
    item: { type: ItemTypes.CARD, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveCard(droppedId, originalIndex);
      }
    },
  });
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop() {
      onDrop();
    },
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findCard(id);
        moveCard(draggedId, overIndex);
      }
    },
  });

  const opacity = isDragging ? 0 : 1;
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [clickDelete, setClickDelete] = useState(0);
  const canDeleteRaffle = useMemo(
    () => hasPermission('delete_promotion_draft_sweepstake', { toast: false }),
    []
  );

  const handleDeleteRaffle = () => {
    if (clickDelete === 0) {
      setClickDelete(1);
      setTimeout(() => setClickDelete(0), 3000);
    } else if (hasPermission('delete_promotion_draft_sweepstake')) {
      setLoadingDelete(true);
      api
        .delete(`/promotions/drafts/${draft._id}/sweepstakes/${id}`)
        .then(res => {
          if (res) onDelete(id);
        })
        .finally(() => setLoadingDelete(false));
    }
  };
  const handleUpdateFile = async data => {
    try {
      const formdata = new FormData();
      formdata.append('titulos', data.item(0));
      setLoadingFile(true);
      const result = await api.post(
        `/promotions/drafts/${id}/updatefile/${draft._id}`,
        formdata
      );
      if (result.data) {
        const resultRaffle = result.data.data.sweepstakes.filter(
          item => item.id === raffle.id
        )[0];
        setFileName(resultRaffle.fileName);
        updateDraft(result.data);
      }
      setLoadingFile(false);
    } catch (error) {
      setLoadingFile(false);
    }
  };

  return (
    <div ref={node => drag(drop(node))} style={{ ...style, opacity }}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <h2>{originalIndex + 1}</h2>
        </Grid>
        <Grid item xs={2}>
          <Img
            width={100}
            src={raffle.urlImagem}
            alt="Sorteio"
            loader={<Skeleton variant="rect" width={100} height={80} />}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={1}
              style={{
                backgroundColor: 'red',
                color: 'white',
                borderRadius: 8,
                textAlign: 'center',
                fontWeight: 'bold',
              }}
              className="animated flash infinite slow"
            >
              <Typography variant="h5">{raffle.quantidade}X </Typography>
            </Grid>
            <Grid item xs={7}>
              <h3>{raffle.descricao}</h3>
              <span>
                {decimalFormat(raffle.valorSugerido)}
                {(product.cod === 'instantaneo' ||
                  product.cod === 'diadasorte' ||
                  product.cod === 'sabadaodasorte') &&
                  raffle.numeroPlano && (
                    <> - Número Plano: {raffle.numeroPlano} </>
                  )}

                {(product.cod === 'instantaneo' ||
                  product.cod === 'diadasorte' ||
                  product.cod === 'sabadaodasorte') &&
                  raffle.numeroSerie && (
                    <> - Número Série: {raffle.numeroSerie} </>
                  )}
              </span>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
                gap: 12,
              }}
              align="right"
            >
              {canDeleteRaffle && draft.status !== 'approved' && (
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={handleDeleteRaffle}
                >
                  {loadingDelete ? (
                    <CircularProgress size={20} style={{ color: 'white' }} />
                  ) : (
                    <>
                      <MdDelete />
                      &nbsp;{clickDelete ? 'Confirma ?' : 'Excluir'}
                    </>
                  )}
                </Button>
              )}
              {(['premiosorteiodiario', 'premiosorteiosemanal'].includes(
                raffleType.codigo
              ) &&
                product.cod === 'instantaneo') ||
                (draft.status !== 'approved' && product.cod === 'instantaneo' && (
                  <>
                    {loadingFile ? (
                      <div style={{ display: 'block', marginTop: 10 }}>
                        <CircularProgress size={20} />
                      </div>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 10,
                        }}
                        align="right"
                      >
                        <span style={{ fontSize: 12 }}>{fileName}</span>

                        <UploadFile
                          name="file"
                          type="file"
                          onChange={item => {
                            handleUpdateFile(item.target.files);
                          }}
                        />
                      </Grid>
                    )}
                  </>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Card;
