/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import EbookAutoComplete from '~/components/EbookAutoComplete';
import isBoolean from 'lodash/isBoolean';
import { Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import api from '~/services/api';
import NewEbookDialog from './NewEbook/NewEbook';
import ListEbooks from './List';

export default function EbookTabs({ draft, updateDraft }) {
  const [loading, setLoading] = useState(false);
  const [enableInsert, setEnableInsert] = useState();
  const [onOpenDialog, setOnOpenDialog] = useState(false);
  const [refetchListForAddEbooks, setRefetchListForAddEbooks] = useState(false);
  const [refetchListAddedEbooks, setRefetchListAddedEbooks] = useState(false);

  const handleOnChange = ebook => {
    setEnableInsert(ebook);
  };

  const handleNewEbook = () => {
    setOnOpenDialog(true);
  };

  const closeNewEbookDialog = isRefetch => {
    setOnOpenDialog(false);
    if (isBoolean(isRefetch)) {
      setRefetchListForAddEbooks(!refetchListForAddEbooks);
    }
  };

  const handleAddEbookToDraft = async () => {
    try {
      setLoading(true);
      const { data } = await api.post(`/promotions/drafts/${draft._id}/ebook`, {
        ebookId: enableInsert,
      });

      if (data) {
        const d = { ...draft };
        d.data.ebooks
          ? (d.data.ebooks = [...d.data.ebooks, data])
          : (d.data.ebooks = [data]);

        updateDraft(d);
      }
      setRefetchListAddedEbooks(!refetchListAddedEbooks);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', gap: '3rem' }}>
        {onOpenDialog && (
          <NewEbookDialog
            open={onOpenDialog}
            onClose={closeNewEbookDialog}
            setRefetch={setRefetchListForAddEbooks}
            refetch={refetchListForAddEbooks}
          />
        )}
        <EbookAutoComplete
          onChange={e => handleOnChange(e.target.value)}
          onClickAdd={handleNewEbook}
          refetch={refetchListForAddEbooks}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            disabled={!enableInsert || loading}
            onClick={handleAddEbookToDraft}
            color="primary"
            variant="contained"
          >
            {loading ? <CircularProgress size={25} /> : 'Inserir'}
          </Button>
        </div>
      </div>
      <ListEbooks
        draft={draft}
        refetch={refetchListAddedEbooks}
        setRefetch={setRefetchListAddedEbooks}
      />
    </>
  );
}

EbookTabs.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateDraft: PropTypes.func.isRequired,
};
