/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import {
  Grid,
  Button,
  CircularProgress,
  Paper,
  Badge,
} from '@material-ui/core';
import { TextMaskNumber } from '~/components/masks';
import AwardsAutocomplete from '~/components/AwardsAutocomplete';
import FormDialog from '~/pages/Backoffice/Awards/Dialog';
import api from '~/services/api';
import AccessControl, { hasPermission } from '~/components/AccessControl';
import List from './List';

import { NewTab } from './styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function RaffleTabs({ draft, raffleTypes, updateDraft }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [award, setAward] = useState([]);
  const [enableInsert, setEnableInsert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prodTrivial, setProdTrivial] = useState(false);
  const [valor, setValor] = useState('1');
  const [qtdAward, setQtdAward] = useState(1);
  const [qtdCupons, setQtdCupons] = useState(1);
  const [type, setType] = useState();
  const product = useSelector(({ user }) => user.product);

  useEffect(() => {
    if (product) {
      setProdTrivial(product.cod === 'instantaneo');
    }
  }, [product]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setValor('1');

    setEnableInsert(null);
  };

  const handleCloseModal = _award => {
    setAward(_award);
    setOpenModal(false);
  };

  const handleClickAward = _award => {
    setOpenModal(false);
    setAward(_award);
    setType(_award.typeRaffle);
    setOpenModal(true);
  };

  const handleSelectAward = _award => {
    setEnableInsert(_award);
  };

  const handleInsert = rt => {
    setLoading(true);
    try {
      if (!draft.data.sweepstakes) draft.data.sweepstakes = [];
      if (hasPermission('create_promotion_draft_sweepstake')) {
        const formdata = new FormData();
        formdata.append('descricao', enableInsert.descricao);
        if (
          (product.cod === 'instantaneo' ||
            product.cod === 'diadasorte' ||
            product.cod === 'sabadaodasorte') &&
          enableInsert.numeroPlano !== null
        ) {
          formdata.append('numeroPlano', enableInsert.numeroPlano);
        }
        if (
          (product.cod === 'instantaneo' ||
            product.cod === 'diadasorte' ||
            product.cod === 'sabadaodasorte') &&
          enableInsert.numeroSerie !== null
        ) {
          formdata.append('numeroSerie', enableInsert.numeroSerie);
        }

        formdata.append('imagemPremio', enableInsert.imagemPremio);
        formdata.append('imagemSorteio', enableInsert.imagemSorteio);
        formdata.append('valorSugerido', enableInsert.valorSugerido);
        formdata.append('tipoSorteio', rt.id);
        formdata.append(
          'quantidade',
          document.getElementById('quantidade').value
        );

        if (prodTrivial) {
          formdata.append(
            'qtdPremios',
            prodTrivial
              ? parseInt(document.getElementById('qtdPremios').value, 10)
              : undefined
          );
          formdata.append(
            'qtdCupons',
            prodTrivial ? document.getElementById('qtdCupons').value : undefined
          );
        }
        formdata.append(
          'ordem',
          draft.data.sweepstakes.filter(r => r.tipoSorteio === rt.id).length + 1
        );
        api
          .post(`/promotions/drafts/${draft._id}/sweepstakes`, formdata)
          .then(({ data }) => {
            if (data) {
              const d = { ...draft };
              d.data.sweepstakes = [...d.data.sweepstakes, data];
              updateDraft(d);
            }
          })
          .finally(() => setLoading(false));
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const validateMinQtyAward = val => {
    if (val === '') {
      setQtdAward(1);
    }
  };
  const validateMinQtyCupons = val => {
    if (val === '') {
      setQtdCupons(1);
    }
  };

  const handleListRaffle = rt => {
    const badge =
      (draft.data.sweepstakes &&
        draft.data.sweepstakes.filter(s => s.tipoSorteio === rt.id).length) ||
      0;

    return (
      <span>
        <Badge
          badgeContent={badge}
          style={{ position: 'absolute' }}
          color="secondary"
          variant="dot"
          invisible={!badge}
        />
        {rt.descricao}
      </span>
    );
  };

  return (
    <div className={classes.root}>
      {openModal && (
        <FormDialog
          product={product}
          award={award}
          opened
          handleCloseModal={handleCloseModal}
          type={type}
        />
      )}

      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Sorteios Tipos"
        className={classes.tabs}
        style={{ width: '17%' }}
      >
        {raffleTypes.map((rt, index) => (
          <NewTab
            key={rt.id}
            label={handleListRaffle(rt)}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>

      {raffleTypes.map((rt, index) => (
        <TabPanel
          key={rt.id}
          value={value}
          index={index}
          style={{ width: '83%' }}
        >
          {draft.status !== 'approved' && (
            <Paper elevation={1} style={{ padding: 10, marginBottom: 20 }}>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} md={prodTrivial ? 5 : 8}>
                  {!openModal && (
                    <AwardsAutocomplete
                      onClickAdd={() =>
                        handleClickAward({
                          produto: product,
                          typeRaffle: rt.id,
                        })
                      }
                      onChange={e => handleSelectAward(e.target.value)}
                      valueKey="key"
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    id="quantidade"
                    label="Quantidade"
                    variant="outlined"
                    margin="normal"
                    defaultValue={1}
                    value={valor}
                    onChange={e => {
                      setValor(e.target.value === '0' ? 1 : e.target.value);
                    }}
                    InputProps={{
                      inputComponent: TextMaskNumber,
                      inputProps: {
                        min: 1,
                      },
                    }}
                    disabled={
                      rt.codigo !== 'girosorte' && rt.codigo !== 'girosimples'
                    }
                  />
                </Grid>
                {product && prodTrivial && (
                  <>
                    <Grid item xs={12} md={2}>
                      <TextField
                        id="qtdPremios"
                        label="Qtd Premios"
                        variant="outlined"
                        defaultValue={1}
                        value={qtdAward}
                        margin="normal"
                        InputProps={{
                          type: 'number',
                          inputProps: {
                            min: 1,
                          },
                        }}
                        onChange={e => {
                          setQtdAward(e.target.value);
                          validateMinQtyAward(e.target.value);
                        }}
                        disabled={!prodTrivial}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        id="qtdCupons"
                        label="Qtd Cupons"
                        value={qtdCupons}
                        defaultValue={1}
                        variant="outlined"
                        margin="normal"
                        InputProps={{
                          type: 'number',
                          inputProps: { min: 1 },
                        }}
                        onChange={e => {
                          setQtdCupons(e.target.value);
                          validateMinQtyCupons(e.target.value);
                        }}
                        disabled={!prodTrivial}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={1} align="right">
                  <AccessControl can="create_promotion_draft_sweepstake">
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!enableInsert}
                      onClick={() => handleInsert(rt)}
                    >
                      {loading ? (
                        <CircularProgress
                          size={20}
                          style={{ color: 'white' }}
                        />
                      ) : (
                        'Inserir'
                      )}
                    </Button>
                  </AccessControl>
                </Grid>
              </Grid>
            </Paper>
          )}
          {draft.data.sweepstakes && !!draft.data.sweepstakes.length && (
            <List
              raffles={draft.data.sweepstakes
                .filter(r => r.tipoSorteio === rt.id)
                .sort((a, b) => a.ordem - b.ordem)}
              draft={draft}
              updateDraft={updateDraft}
              raffleType={rt}
              inserting={loading}
            />
          )}
        </TabPanel>
      ))}
    </div>
  );
}

RaffleTabs.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  raffleTypes: PropTypes.oneOfType([PropTypes.array]).isRequired,
  updateDraft: PropTypes.func.isRequired,
};
