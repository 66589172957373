import styled from 'styled-components';
import { Grid, CardMedia } from '@material-ui/core';
import CsvDownloader from 'react-csv-downloader';

// -- components
import { Paper } from '../../Coupons/styles';

export const CsvDownload = styled(CsvDownloader)`
  display: inline;
`;

export const PromotionContainer = styled(Grid).attrs({
  item: true,
  xs: 12,
  md: 12,
})`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

// -- Promotion Sweepstake components
export const SweepstakePicture = styled(CardMedia).attrs({
  component: 'img',
})`
  width: 100%;
`;

export const SweepstakePaper = styled(Paper)`
  width: 100%;
  justify-content: flex-start;
`;

// -- Sweepstake winners components
export const WinnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const WinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  padding: 15px 0px;
  border-bottom: 1px solid #cccccc;

  &:last-child {
    border-bottom: 0px solid transparent;
  }
`;

export const WinnerAvatar = styled.picture`
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
  min-width: 80px;
  min-height: 80px;
  margin-right: 15px;
  background-position: center center;
  background-size: cover;
  background-image: url("${({ url }) => url}");
  border-radius: 40px;
  box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.15);
  border:2px solid #FFF;
`;

export const WinnerContainerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  button.titulo {
    background-color: #ffffff;
    border: 0px solid transparent;
  }
`;

export const WinnerData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  > span {
    margin-bottom: 7px;
    font-size: 16px;

    &:last-child {
      margin-bottom: 0px;
      border-bottom: 0px;
    }
  }
`;

export const WinnerActions = styled.div`
  margin-left: 15px;
  padding: 0px 5px;

  button + button {
    margin: 0px 5px;
  }

  .MuiButton-label {
    display: flex;
    align-items: center;
    flex-direction: column;

    svg {
      font-size: 20px;
      margin-bottom: 4px;
    }
  }
`;
