import { useState, useEffect } from 'react';
import { applySpec, prop, pipe, map } from 'ramda';

import api from '~/services/api';

const productSpec = applySpec({
  id: prop('idProduto'),
  titulo: prop('descricao'),
  cod: prop('codigo'),
});

const getProducts = pipe(prop('docs'), map(productSpec));

export default function useProducts() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      api
        .get('/products?limit=50')
        .then(({ data }) => {
          if (data) {
            setProducts(getProducts(data));
          }
        })
        .finally(() => setLoading(false));
    } catch (error) {
      setLoading(false);
    }
  }, []);

  return { products, loading };
}
