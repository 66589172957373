import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import EbookTabs from './tabs';

export default function Ebook({ draft, updateDraft, setNextStep }) {
  useEffect(() => {
    setNextStep(
      draft.data.regulation &&
        draft.data.banners &&
        draft.data.banners.length &&
        draft.data.ebooks &&
        draft.data.ebooks.length &&
        draft._id
    );
    // eslint-disable-next-line
  }, [draft]);

  return (
    <Grid style={{ backgroundColor: '#fff' }} container spacing={2}>
      <Grid
        style={{
          padding: '3rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          boxSizing: 'border-box',
        }}
        item
        xs={12}
      >
        <EbookTabs draft={draft} updateDraft={updateDraft} />
      </Grid>
    </Grid>
  );
}

Ebook.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateDraft: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
};
