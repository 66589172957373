import React, { useState, useEffect, useCallback } from 'react';
import {
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import api from '~/services/api';

import { NoRecord } from '~/components';
import DeleteButton from './deleteButton';

export default function PromotionActionsGiftsList({
  product,
  loadingData,
  list,
}) {
  const [acaoList, setAcaoList] = useState([]);
  const [brindeList, setBrindeList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  const handleListAcao = useCallback(async () => {
    setLoadingList(true);
    const [brindes, acoes] = await Promise.all([
      api.get('/promotions/gifts'),
      api.get('/promotions/actions'),
    ]);
    if (brindes.data.docs.rows) {
      const bs = [];
      brindes.data.docs.rows.forEach(b => {
        bs[b.idBrinde] = b.nome;
      });
      setBrindeList(bs);
    }
    if (acoes.data.docs.rows) {
      const al = [];
      acoes.data.docs.rows.forEach(a => {
        al[a.idAcao] = a.nome;
      });
      setAcaoList(al);
    }
    await loadingData();
    setLoadingList(false);
  }, [loadingData]);

  useEffect(() => {
    handleListAcao();
  }, [product, handleListAcao]);

  const renderList = (item, count) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={`${item.idAcao}-${item.idBrinde}`}
      >
        <TableCell>{count + 1}</TableCell>
        <TableCell>{acaoList[item.idAcao] || 'Carregando ação...'}</TableCell>
        <TableCell>
          {brindeList[item.idBrinde] || 'Carregando brinde...'}
        </TableCell>
        <TableCell>
          <DeleteButton
            product={product}
            item={item}
            loadingData={loadingData}
          />
        </TableCell>
      </TableRow>
    );
  };
  return (
    <>
      <h3 style={{ marginLeft: 10, marginBottom: 20, marginTop: 40 }}>
        AÇÕES BRINDES CADASTRADAS
      </h3>
      <hr style={{ margin: 10 }} />

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Nº</TableCell>
              <TableCell>Ação</TableCell>
              <TableCell>Brinde</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {loadingList && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress size={20} style={{ color: 'black' }} />
                </TableCell>
              </TableRow>
            )}

            {!loadingList && list.length > 0 ? (
              list.map((item, count) => renderList(item, count))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {!loadingList && <NoRecord />}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

PromotionActionsGiftsList.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  list: PropTypes.oneOfType([PropTypes.array]).isRequired,
  loadingData: PropTypes.func.isRequired,
};
