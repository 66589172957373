import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { CircularProgress, Chip } from '@material-ui/core';
import ReactJson from 'react-json-view';
import api from '~/services/api';
import NoRecord from '~/components/NoRecord';
import { hasPermission } from '~/components/AccessControl';
import useCustomPaginator from '~/components/CustomPaginator/useCustomPaginator';
import CustomPaginator from '~/components/CustomPaginator';
import { Table } from './styles';

export default function Log({ draft }) {
  const ACTIONS = [
    {
      value: 'promotion_draft_created',
      name: 'Criado',
      color: 'primary',
    },
    {
      value: 'promotion_draft_updated',
      name: 'Atualizado',
      color: 'primary',
    },
    {
      value: 'promotion_draft_add_new_banner',
      name: 'Novo Banner',
      color: 'primary',
    },
    {
      value: 'promotion_draft_delete_banner',
      name: 'Banner Excluído',
      color: 'secondary',
    },
    {
      value: 'promotion_draft_add_new_sweepstake',
      name: 'Novo Sorteio',
      color: 'primary',
    },
    {
      value: 'promotion_draft_delete_sweepstake',
      name: 'Sorteio Excluído',
      color: 'secondary',
    },
    {
      value: 'promotion_draft_add_new_ebook',
      name: 'Novo ebook',
      color: 'primary',
    },
    {
      value: 'promotion_draft_delete_ebook',
      name: 'Ebook Excluído',
      color: 'secondary',
    },
    {
      value: 'promotion_draft_pending',
      name: 'Enviado para Aprovação',
      color: 'secondary',
      style: { backgroundColor: '#FFEB3B', color: '#000000' },
    },
    {
      value: 'promotion_draft_approved',
      name: 'Aprovado',
      color: 'secondary',
      style: { backgroundColor: '#4CAF50' },
    },
    {
      value: 'promotion_draft_deleted',
      name: 'Excluído',
      color: 'secondary',
      style: { backgroundColor: '#FF0000', color: '#FFFFFF' },
    },
  ];

  const [result, setResult] = useState([]);

  const [loading, setLoading] = useState(false);
  const { setPagination, ...customPaginator } = useCustomPaginator();

  const getLogs = useCallback(async () => {
    setLoading(true);
    try {
      if (hasPermission('read_promotion_draft_logs')) {
        const { data } =
          (await api.get(
            `/promotions/drafts/${draft._id}/logs?page=${customPaginator.pagination.page}&limit=${customPaginator.pagination.limit}`
          )) || {};

        setResult(data);

        // set paginator.
        const { page, pages, total } = data;
        setPagination({
          ...{
            limit: customPaginator.pagination.limit,
            page,
            pages,
            totals: total,
          },
        });
      }
    } finally {
      setLoading(false);
    }
  }, [
    draft._id,
    customPaginator.pagination.limit,
    customPaginator.pagination.page,
    setPagination,
  ]);

  function renderAction(item) {
    if (item.action) {
      const act = ACTIONS.find(a => a.value === item.action.name) || {};
      return (
        <Chip
          size="small"
          label={act.name || 'não definido'}
          color={act.color || undefined}
          style={act.style || null}
        />
      );
    }
    return '';
  }

  function renderResource(item) {
    if (item.action.modifiedIn && item.action.modifiedIn.length) {
      switch (item.action.modifiedIn[0].resource) {
        case 'PaymentMethod':
        case 'QuarantinePaymentMethod':
          return (
            <>
              <p>
                {item.action.modifiedIn[0].entities[0].bandeiraCartao}{' '}
                {item.action.modifiedIn[0].entities[0].numeroCartao}
              </p>
              <p>
                <i>
                  {item.action.modifiedIn[0].entities[0].nomeCompletoCartao}
                </i>
              </p>
            </>
          );
        default:
          return '';
      }
    }
    return '';
  }

  useEffect(() => {
    if (draft && draft._id) getLogs();
    // TODO:
  }, [draft, getLogs]);

  if (loading && (!result || !result.docs)) {
    return <CircularProgress size={24} />;
  }
  if (result && result.docs && result.docs.length > 0) {
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Usuário</th>
              <th>Ação</th>
              <th>Dados Afetados</th>
            </tr>
          </thead>
          <tbody>
            {result.docs.map(item => (
              // eslint-disable-next-line no-underscore-dangle
              <tr key={item._id}>
                <td>
                  {format(parseISO(item.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                </td>
                <td>
                  {item.user && item.user.name}
                  <p>{item.user && item.user.email}</p>
                </td>
                <td>
                  {renderAction(item)}
                  {renderResource(item)}
                </td>
                <td>
                  <ReactJson
                    displayObjectSize={false}
                    displayDataTypes={false}
                    enableClipboard={false}
                    collapsed={false}
                    sortKeys
                    defaultValue=""
                    src={item.action.data || {}}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <CustomPaginator {...{ loading, setPagination, ...customPaginator }} />
        {loading && <CircularProgress size={24} />}
      </>
    );
  }
  return <NoRecord message="Nenhum registro de log encontrado." />;
}

Log.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
