import React, { useState, useEffect, useCallback } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import RaffleTabs from './tabs';

export default function Raffle({ draft, updateDraft, setNextStep }) {
  useEffect(() => {
    setNextStep(
      draft.data.regulation &&
        draft.data.banners &&
        draft.data.banners.length &&
        draft.data.sweepstakes &&
        draft.data.sweepstakes.length &&
        draft._id
    );
    // eslint-disable-next-line
  }, [draft]);

  const [raffleTypes, setRaffleTypes] = useState();
  const [loading, setLoading] = useState();
  const getRaffleTypes = useCallback(async () => {
    setLoading(true);
    try {
      await api
        .get(
          `/promotions/config/${draft.data.promotion.idProduto}?byProduct=true`
        )
        .then(({ data }) => {
          if (data) setRaffleTypes(data.config.tipoSorteio);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      setLoading(false);
    }
  }, [draft.data.promotion.idProduto]);

  useEffect(() => {
    if (hasPermission('read_sweepstakes_types')) {
      getRaffleTypes();
    }
  }, [getRaffleTypes]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {loading && <CircularProgress />}
        {raffleTypes && (
          <RaffleTabs
            raffleTypes={raffleTypes}
            draft={draft}
            updateDraft={updateDraft}
          />
        )}
      </Grid>
    </Grid>
  );
}

Raffle.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateDraft: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
};
