import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  CircularProgress,
  Link,
  Typography,
  Button,
} from '@material-ui/core';
import { format as formatDate, parseISO, isDate, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  MdCardGiftcard,
  MdSettings,
  MdCheck,
  MdClose,
  MdPictureAsPdf,
  MdImage,
} from 'react-icons/md';
import Img from 'react-image';
import Skeleton from '@material-ui/lab/Skeleton';

import NO_BANNER_IMAGE from '~/assets/no-banner.jpg';
import { brlToNumber, decimalFormat } from '~/helpers/number';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import RenderEbook from './RenderEbook';
import { ColorColor } from '../../Config/Form/styles';
import { Content, Title, Value, DivIcon, Results } from './styles';

const colors = [
  <ColorColor color="#773386">&nbsp;#773386</ColorColor>,
  <ColorColor color="#ac0808">&nbsp;#ac0808</ColorColor>,
  <ColorColor color="#faa21d">&nbsp;#faa21d</ColorColor>,
  <ColorColor color="#8b5528">&nbsp;#8b5528</ColorColor>,
  <ColorColor color="#00a7b9">&nbsp;#00a7b9</ColorColor>,
];

function parseDateWithFormat(date, format) {
  if (isDate(date) && isValid(date))
    return formatDate(date, format, { locale: ptBR });

  return typeof date === 'string' && isValid(parseISO(date))
    ? formatDate(parseISO(date), format, { locale: ptBR })
    : null;
}

export default function Review({ draft, product }) {
  const [raffleTypes, setRaffleTypes] = useState();
  const [raffleList, setRaffleList] = useState();
  const [ebooksList, setEbooksList] = useState();
  const [loading, setLoading] = useState();
  const [institution, setInstitution] = useState({});
  const [cgSusep, setCgSusep] = useState({});
  const getRaffleTypes = async () => {
    setLoading(true);
    try {
      await api
        .get('/sweepstake/types')
        .then(({ data }) => {
          if (data) setRaffleTypes(data.docs);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      setLoading(false);
    }
  };

  const getInstitution = useCallback(() => {
    if (draft.data.promotion.idInstituicao) {
      api
        .get(`/institutions?id=${draft.data.promotion.idInstituicao}`)
        .then(({ data }) => {
          if (data && data.docs.length) setInstitution(data.docs[0]);
        });
    }
  }, [draft.data.promotion.idInstituicao]);

  const getCgSusep = useCallback(() => {
    if (draft.data.promotion.idCgSusep) {
      api
        .get(`/cgs-susep/${draft.data.promotion.idCgSusep}`)
        .then(({ data }) => {
          if (data) setCgSusep(data);
        });
    }
  }, [draft.data.promotion.idCgSusep]);

  useEffect(() => {
    if (hasPermission('read_institutions')) {
      getInstitution();
    }
    if (hasPermission('read_cgs_susep')) {
      getCgSusep();
    }
  }, [draft, getCgSusep, getInstitution]);

  useEffect(() => {
    if (hasPermission('read_sweepstakes_types')) {
      getRaffleTypes();
    }
  }, []);

  const renderBooleanValue = value => {
    return value ? (
      <DivIcon color="green">
        <MdCheck size={22} />
        <span>SIM</span>
      </DivIcon>
    ) : (
      <DivIcon color="red">
        <MdClose size={22} />
        <span>NÃO</span>
      </DivIcon>
    );
  };

  const renderBanner = banner => {
    return (
      <Grid
        key={banner.id}
        container
        item
        xs={12}
        spacing={3}
        alignItems="center"
        justifyContent="space-around"
      >
        <Grid item xs={1}>
          <h2>{banner.order}</h2>
        </Grid>
        <Grid item xs={4}>
          <Link href={banner.url} target="_blank">
            <Img
              width={200}
              src={banner.url}
              alt="Banner Home"
              loader={<Skeleton variant="rect" width={200} height={200} />}
              unloader={
                <img src={NO_BANNER_IMAGE} alt="Banner Home" width={200} />
              }
            />
          </Link>
        </Grid>
        <Grid item xs={7}>
          <a href={banner.urlVideo} target="_blank" rel="noreferrer noopener">
            <p>{banner.urlVideo}</p>
          </a>
        </Grid>
        <hr style={{ marginTop: 30, marginBottom: 30, width: 1200 }} />
      </Grid>
    );
  };

  const renderRaffles = raffle => {
    return (
      <Grid container spacing={2} key={raffle.id}>
        <Grid item xs={1}>
          <h2>{raffle.ordem}</h2>
        </Grid>
        <Grid item xs={2}>
          <Link href={raffle.urlImagem} target="_blank">
            <Img
              width={200}
              src={raffle.urlImagem}
              alt="Sorteio"
              loader={<Skeleton variant="rect" width={200} height={200} />}
            />
          </Link>
        </Grid>
        {raffle.urlImagemPremio && (
          <Grid item xs={2}>
            <Img
              height={130}
              src={raffle.urlImagemPremio}
              alt="Sorteio"
              loader={<Skeleton variant="rect" width={200} height={200} />}
            />
          </Grid>
        )}
        <Grid item xs={7}>
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={1}
              style={{
                backgroundColor: 'red',
                color: 'white',
                borderRadius: 8,
                textAlign: 'center',
                fontWeight: 'bold',
              }}
              className="animated flash infinite slow"
            >
              {/* {raffle.quantidade && raffle.quantidade >= 2 && ( */}
              <Typography variant="h5">{raffle.quantidade} X </Typography>
              {/* )} */}
            </Grid>
            <Grid item xs={5}>
              <h3>{raffle.descricao}</h3>
              <span>{decimalFormat(raffle.valorSugerido)}</span>
            </Grid>
            <Grid item xs={6}>
              {raffle.qtdDesbloqueiaPremio && (
                <>
                  <h3>
                    Quantidade de prêmios:
                    <Results>
                      {raffle.qtdPremiosDisponiveis || 'Prêmios ilimitados'}
                    </Results>
                  </h3>
                  {'\n'}
                  <h3>
                    Quantidade de desbloqueio de prêmios:
                    <Results>{raffle.qtdDesbloqueiaPremio}</Results>
                  </h3>
                </>
              )}
              {(product.cod === 'instantaneo' ||
                product.cod === 'diadasorte' ||
                product.cod === 'sabadaodasorte') && (
                <>
                  {raffle.numeroPlano && (
                    <h3>
                      Número Plano:
                      <Results>{raffle.numeroPlano}</Results>
                    </h3>
                  )}

                  {raffle.numeroSerie && (
                    <h3>
                      Número Série:
                      <Results>{raffle.numeroSerie}</Results>
                    </h3>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handlePageSusep = processo => {
    setLoading(true);
    api.get(`/cgs-susep/third?numeroProcesso=${processo}`).then(({ data }) => {
      const newWindow = window.open('', '_blank');
      newWindow.document.write(data);
      setLoading(false);
    });
  };
  const chance = value => {
    if (value === 1) return 'SIMPLES';
    if (value === 2) return 'DUPLA';
    if (value === 3) return 'TRIPLA';
    return 'Sem categoria';
  };

  useEffect(() => {
    if (raffleTypes && draft?.data?.sweepstakes) {
      const tipoSorteio = draft.data.sweepstakes.map(item => item.tipoSorteio);
      const newRaffle = raffleTypes.filter(rt => tipoSorteio.includes(rt.id));
      setRaffleList(newRaffle);
    }
  }, [raffleTypes, draft.data.sweepstakes]);

  useEffect(() => {
    if (draft?.data?.ebooks) {
      setEbooksList(draft.data.ebooks);
    }
  }, [draft.data.ebooks]);

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        {/* Painel da Promoção */}
        <Grid item xs={12} md={6}>
          <Content>
            <Grid
              container
              spacing={2}
              style={{
                padding: 10,
                marginTop: 10,
                borderBottom: 'solid 1px #f4f4f4',
              }}
            >
              <Grid item xs={12} md={8}>
                <h2
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <MdCardGiftcard size={26} />
                  Detalhes da Promoção
                </h2>
              </Grid>
              <Grid item xs={12} md={4} align="right">
                <h2>R$ {draft.data.promotion.valorProduto}</h2>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={3}
                style={{
                  marginTop: 10,
                }}
              >
                <Img
                  src={product.imagem}
                  width="100%"
                  alt={product.titulo}
                  loader={
                    <Skeleton variant="rect" width="100%" height="auto" />
                  }
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid
                  container
                  spacing={2}
                  style={{
                    padding: 10,
                    marginTop: 10,
                    borderBottom: 'solid 1px #f4f4f4',
                  }}
                >
                  <Grid item xs={12}>
                    <h1>{product.titulo}</h1>
                    <Value style={{ marginTop: 8 }}>
                      {draft.data.promotion.tituloPromocao}
                    </Value>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{
                    padding: 10,
                    marginTop: 11,
                    borderBottom: 'solid 1px #f4f4f4',
                  }}
                >
                  <Grid item xs={5}>
                    <Title>Data Referência</Title>
                    <Value>
                      {formatDate(
                        parseISO(`${draft.data.promotion.dataReferencia}`),
                        'EEEE, dd/MM/yyyy',
                        { locale: ptBR }
                      )}
                    </Value>
                  </Grid>
                  <Grid item xs={5}>
                    <Title>Data Sorteio Principal</Title>
                    <Value>
                      {formatDate(
                        parseISO(draft.data.promotion.dataSorteioPrincipal),
                        'EEEE, dd/MM/yyyy HH:mm',
                        { locale: ptBR }
                      )}
                    </Value>
                  </Grid>
                  <Grid item xs={2}>
                    <Title>Extração</Title>
                    <Value>
                      <h4>{draft.data.promotion.extracao}</h4>
                    </Value>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{
                    padding: 10,
                    marginTop: 10,
                    borderBottom: 'solid 1px #f4f4f4',
                  }}
                >
                  <Grid item xs={12}>
                    <Title>Vigência</Title>
                    <Value style={{ fontSize: 13 }}>
                      {parseDateWithFormat(
                        draft.data.promotion.dataVigenciaInicio,
                        'EEEE, dd/MM/yyyy HH:mm:ss'
                      )}
                      {/* {formatDate(
                        parseISO(draft.data.promotion.dataVigenciaInicio),
                        'EEEE, dd/MM/yyyy HH:mm:ss',
                        { locale: ptBR }
                      )} */}
                      &nbsp;até&nbsp;
                      {parseDateWithFormat(
                        draft.data.promotion.dataVigenciaFim,
                        'EEEE, dd/MM/yyyy HH:mm:ss'
                      )}
                      {/* {formatDate(
                        parseISO(draft.data.promotion.dataVigenciaFim),
                        'EEEE, dd/MM/yyyy HH:mm:ss',
                        { locale: ptBR }
                      )} */}
                    </Value>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{
                    padding: 12,
                  }}
                >
                  <Grid item xs={12}>
                    <Title>CG Susep</Title>
                    <Value>
                      {cgSusep && cgSusep.processoSusep ? (
                        <Button
                          variant="text"
                          onClick={() => handlePageSusep(cgSusep.processoSusep)}
                          target="_blank"
                        >
                          <h4>
                            {cgSusep.nomeComercial} - {cgSusep.processoSusep}
                          </h4>
                        </Button>
                      ) : (
                        ' - '
                      )}
                    </Value>
                  </Grid>

                  <Grid item xs={12}>
                    <Title>Quota de Resgate</Title>
                    {cgSusep && cgSusep.quotaResgate !== undefined ? (
                      <strong>
                        {cgSusep.quotaResgate}% (R${' '}
                        {decimalFormat(
                          brlToNumber(draft.data.promotion.valorProduto) *
                            (cgSusep.quotaResgate / 100)
                        )}
                        )
                      </strong>
                    ) : (
                      ' - '
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Title>Modalidade</Title>
                    {draft.data.promotion.modalidade !== undefined ? (
                      <h4>{draft.data.promotion.modalidade}</h4>
                    ) : (
                      ' - '
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Content>
        </Grid>

        {/* Painel de Condigurações */}
        <Grid item xs={12} md={6}>
          <Content>
            <Grid
              container
              spacing={2}
              style={{
                padding: 10,
                marginTop: 10,
                borderBottom: 'solid 1px #f4f4f4',
              }}
            >
              <Grid item xs={12} md={12}>
                <h2
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <MdSettings size={26} />
                  Configuração
                </h2>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                padding: 10,
                marginTop: 10,
                borderBottom: 'solid 1px #f4f4f4',
              }}
            >
              <Grid item xs={12} md={4}>
                <Title>Intervalo de Títulos</Title>
                <Value>
                  {draft.data.promotion.numTituloInicio.toLocaleString()}
                  &nbsp;até&nbsp;
                  {draft.data.promotion.numTituloFim.toLocaleString()}
                </Value>
              </Grid>
              <Grid item xs={12} md={4}>
                <Title>Intervalo de Titulos PDV</Title>
                <Value>
                  {draft.data.promotion.numTituloInicioPdv &&
                  draft.data.promotion.numTituloFimPdv
                    ? `${draft.data.promotion.numTituloInicioPdv.toLocaleString()} até ${draft.data.promotion.numTituloFimPdv.toLocaleString()}`
                    : '-'}
                </Value>
              </Grid>
              <Grid item xs={12} md={4}>
                <Title>Categoria</Title>
                <Value>
                  {/* {draft.data.promotion.categoria === 1 ? 'SIMPLES' : 'DUPLA'} */}
                  {chance(draft.data.promotion.categoria)}
                </Value>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                padding: 10,
                marginTop: 10,
                borderBottom: 'solid 1px #f4f4f4',
              }}
            >
              <Grid item xs={12} md={4}>
                <Title>Pulo</Title>
                <Value>
                  {draft.data.promotion.categoria > 1
                    ? draft.data.promotion.pulo.toLocaleString()
                    : '-'}
                </Value>
              </Grid>
              <Grid item xs={12} md={4}>
                <Title>Imprime Cartão</Title>
                <Value>
                  {renderBooleanValue(draft.data.promotion.imprimirCartao)}
                </Value>
              </Grid>
              <Grid item xs={12} md={4}>
                <Title>Venda Online</Title>
                <Value>
                  {renderBooleanValue(draft.data.promotion.vendaOnline)}
                </Value>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                padding: 10,
                marginTop: 10,
                borderBottom: 'solid 1px #f4f4f4',
              }}
            >
              <Grid item xs={12} md={4}>
                <Title>Número Extra</Title>
                <Value>
                  {renderBooleanValue(draft.data.promotion.numeroExtra)}
                </Value>
              </Grid>
              <Grid item xs={12} md={4}>
                <Title>Mostra Game</Title>
                <Value>
                  {renderBooleanValue(draft.data.promotion.mostraGame)}
                </Value>
              </Grid>
              <Grid item xs={12} md={4}>
                <Title>Cor da promoção</Title>
                <Value>{colors[draft.data.promotion.corPromocao]}</Value>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                padding: 10,
                marginTop: 10,
                borderBottom: 'solid 1px #f4f4f4',
              }}
            >
              <Grid item xs={12} md={3}>
                <Title>Quant. mínima</Title>
                <Value>{draft.data.promotion.qtdMinimaCupons || '-'}</Value>
              </Grid>
              <Grid item xs={12} md={3}>
                <Title>Quant. máxima</Title>
                <Value>{draft.data.promotion.qtdMaximaCupons || '-'}</Value>
              </Grid>
              <Grid item xs={12} md={3}>
                <Title>Quant. sugerida 1</Title>
                <Value>{draft.data.promotion.qtdSugerida1 || '-'}</Value>
              </Grid>
              <Grid item xs={12} md={3}>
                <Title>Quant. sugerida 2</Title>
                <Value>{draft.data.promotion.qtdSugerida2 || '-'}</Value>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                padding: 10,
                marginTop: 10,
              }}
            >
              <Grid item xs={12} md={3}>
                <Title>Regulamento</Title>
                <Value>
                  {draft.data.regulation ? (
                    <Link
                      href={draft.data.regulation}
                      target="_blank"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <MdPictureAsPdf size={28} /> Ver
                    </Link>
                  ) : (
                    'Indisponível'
                  )}
                  <br />
                </Value>
              </Grid>
              <Grid item xs={12} md={2}>
                <Title>Matriz</Title>
                <Value>{draft.data.promotion.matriz}</Value>
              </Grid>

              {product.cod !== 'instantaneo' &&
                product.cod !== 'diadasorte' &&
                product.cod !== 'sabadaodasorte' && (
                  <>
                    {' '}
                    <Grid item xs={12} md={2}>
                      <Title>Número Plano</Title>
                      <Value style={{ fontSize: 13 }}>
                        {draft.data.promotion.numeroPlano}
                      </Value>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Title>Número de série</Title>
                      <Value style={{ fontSize: 13 }}>
                        {draft.data.promotion.numeroSerie}
                      </Value>
                    </Grid>
                  </>
                )}

              <Grid item xs={12} md={12}>
                <Title>Instituição</Title>
                <Value style={{ fontSize: 13 }}>{institution.nome}</Value>
              </Grid>
            </Grid>
          </Content>
        </Grid>

        {draft.data.promotion.imagemCapa && (
          <Grid item xs={12} md={12}>
            <Content>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <h2
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <MdImage size={26} />
                    Imagem Capa
                  </h2>
                </Grid>
                <Grid>
                  <div style={{ left: 50 }}>
                    <img
                      style={{ width: 300 }}
                      src={draft.data.promotion.imagemCapa}
                      alt="imagem de capa"
                    />
                  </div>
                </Grid>
              </Grid>
            </Content>
          </Grid>
        )}

        {/* Painel de Banners */}
        <Grid item xs={12} md={12}>
          <Content>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <h2
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: 0,
                  }}
                >
                  <MdImage size={26} />
                  Banners Home
                </h2>
              </Grid>
              <Grid item xs={4} md={12}>
                {draft.data.banners &&
                  draft.data.banners
                    .sort((a, b) => a.order - b.order)
                    .map(b => renderBanner(b))}
              </Grid>
            </Grid>
          </Content>
        </Grid>

        {/* Painel de Sorteios */}
        {raffleList &&
          raffleList.map(rt => (
            <Grid key={rt.id} item xs={12} md={12}>
              <Content>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <h2
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      * {rt.descricao}
                    </h2>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {draft.data.sweepstakes &&
                      draft.data.sweepstakes
                        .filter(s => s.tipoSorteio === rt.id)
                        .sort((a, b) => a.ordem - b.ordem)
                        .map(s => renderRaffles(s))}
                  </Grid>
                </Grid>
              </Content>
            </Grid>
          ))}

        {/* Painel de Ebooks */}
        {ebooksList && ebooksList.length && (
          <Grid item xs={12} md={12}>
            <Content style={{ gap: '2rem' }}>
              <h2>Ebooks</h2>
              {ebooksList &&
                ebooksList.map(ebook => <RenderEbook ebook={ebook} />)}
            </Content>
          </Grid>
        )}
      </Grid>
    );
  };

  return !loading && draft && draft.data ? renderMain() : <CircularProgress />;
}

Review.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
