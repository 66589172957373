/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import {
  Grid,
  Button,
  CircularProgress,
  Icon,
  InputLabel,
  InputAdornment,
  IconButton,
  TextField as MUITextField,
} from '@material-ui/core';
import { TextField, Select } from 'unform-material-ui';
import * as Yup from 'yup';
import { last, get, set, isEmpty } from 'lodash';
import useMountEffect from '@restart/hooks/useMountEffect';

import {
  isValid as isValidDate,
  format as formatDate,
  isValid,
  format,
  parseISO,
  subDays,
  subHours,
  addHours,
  startOfDay,
  endOfDay,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';

import { ptBR } from 'date-fns/locale';
import { AccessControl, UnFormDatePicker, IDatePicker } from '~/components';

import api from '~/services/api';

import { hasPermission } from '~/components/AccessControl';
import { yupDateTransformer, parse as parseDate } from '~/helpers/date';
import TextMaskCurrency from '~/components/masks/TextMaskCurrency';

import { TextMaskTime, TextMaskNumber } from '~/components/masks';
import InstitutionsAutocomplete from '~/components/InstitutionsAutocomplete';
import CgsSusepAutocomplete from '~/components/CgsSusepAutocomplete';
import { Content } from './styles';

let timeValidade;
let configIntervalId;

// Yup form schema validation
const schema = Yup.object().shape({
  data: Yup.object().shape({
    promotion: Yup.object().shape({
      dataReferencia: Yup.mixed()
        .required('Campo obrigatório')
        .transform(yupDateTransformer())
        .test('is-date', 'Data Inválida', isValidDate),
      tituloPromocao: Yup.string().required('Campo obrigatório'),
      valorProduto: Yup.string().required('Campo obrigatório'),
      // eslint-disable-next-line func-names
      categoria: Yup.string().when('$produto', function(produto) {
        if (produto.cod === 'instantaneo') {
          return this.nullable().optional();
        }
        return this.required('Campo obrigatório');
      }),
      numeroSerie: Yup.string()
        .nullable()
        .optional(),
      horarioSorteio: Yup.string()
        .required('Campo obrigatório')
        .matches(
          /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
          'Horário inválido'
        ),
      dataVigenciaInicio: Yup.mixed()
        .notRequired()
        .transform(yupDateTransformer()),
      dataVigenciaFim: Yup.mixed()
        .notRequired()
        .transform(yupDateTransformer()),
      idInstituicao: Yup.string().nullable(),
      idCgSusep: Yup.string()
        .notRequired()
        .nullable(),
      qtdMinimaCupons: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      qtdMaximaCupons: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      qtdSugerida1: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
      qtdSugerida2: Yup.string()
        .nullable()
        .matches(/^(?:\d+)?$/, 'Deve ser um número ou nulo'),
    }),
    // eslint-disable-next-line func-names
    file: Yup.mixed().when('$produto', function(produto) {
      if (produto.cod === 'instantaneo') {
        this.test('is-file', 'Você deve anexar um arquivo', file => {
          return file instanceof File;
        });
      }
    }),
  }),
});

// sign of the function compoment
export default function PromotionDraftForm({
  product,
  draft,
  updateDraft,
  setNextStep,
  config,
}) {
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState();
  const currentConfig = useRef(null);

  const [dataVigencia, setDataVigencia] = useState(() => {
    const promotion = get(draft, 'data.promotion', {});

    const inicial = promotion.dataVigenciaInicio
      ? parseDate(promotion.dataVigenciaInicio).toISOString()
      : null;

    const final = promotion.dataVigenciaFim
      ? parseDate(promotion.dataVigenciaFim).toISOString()
      : null;

    return { inicial, final };
  });

  const [extracao, setExtracao] = useState(() => {
    const promotion = get(draft, 'data.promotion', {});
    return promotion.extracao || '';
  });

  const [dataReferencia, setDataReferencia] = useState(() => {
    const promotion = get(draft, 'data.promotion', {});
    const date = parseISO(promotion.dataReferencia);
    return isValid(date) ? date : '';
  });
  const getConfig = useCallback(async () => {
    try {
      if ((!draft || !draft._id) && hasPermission('read_promotion_config')) {
        const { data } =
          (await api.get(`/promotions/config/${product.id}?byProduct=true`)) ||
          {};
        if (data) {
          currentConfig.current = { ...data };
          setInitialData({
            data: {
              promotion: {
                categoria: data.config.categoria,
                horarioSorteio: data.config.horarioSorteio,
                idInstituicao: data.config.idInstituicao,
                qtdMinimaCupons: data.config.qtdMinimaCupons,
                qtdMaximaCupons: data.config.qtdMaximaCupons,
                qtdSugerida1: data.config.qtdSugerida1,
                qtdSugerida2: data.config.qtdSugerida2,
              },
            },
          });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [product, draft, setInitialData]);

  useMountEffect(() => {
    if (configIntervalId) clearTimeout(configIntervalId);

    setTimeout(() => {
      if (!currentConfig.current) {
        try {
          api
            .get(`/promotions/config/${product.id}?byProduct=true`)
            .then(response => {
              currentConfig.current = response.data;
            });
        } catch (err) {
          // nothing...
        }
      }
    }, 200);
  });

  const validateForm = useCallback(
    e => {
      const form = e.currentTarget;
      const fields = [
        ...form.querySelectorAll(
          "input[type='text'],input[type='tel'],input[type='hidden'],input[aria-hidden='true']"
        ),
      ];
      if (timeValidade) clearTimeout(timeValidade);
      timeValidade = setTimeout(async () => {
        const data = fields
          .filter(f => f.name)
          .reduce((acc, field) => {
            const name = last(field.name.split('.'));
            acc[name] = field.value;
            return acc;
          }, {});
        setNextStep(
          schema.isValidSync(
            { data: { promotion: data } },
            { abortEarly: true, context: { produto: product } }
          ) && draft._id
        );
      }, 200);
    },
    [draft._id, setNextStep, product]
  );

  function getDateFormat(data) {
    const newDate = parseISO(`${data}T23:00:00.000Z`);
    return format(newDate, 'yyyy-MM-dd HH:mm', { locale: ptBR });
  }

  // Send form to api
  const handleSubmit = async dataUpdate => {
    try {
      const formdata = new FormData();
      dataUpdate = schema.cast(dataUpdate, { context: { produto: product } });
      formdata.append(
        'qtdMinimaCupons',
        parseFloat(dataUpdate.data.promotion.qtdMinimaCupons)
      );
      formdata.append(
        'qtdMaximaCupons',
        parseFloat(dataUpdate.data.promotion.qtdMaximaCupons)
      );
      formdata.append(
        'qtdSugerida1',
        parseFloat(dataUpdate.data.promotion.qtdSugerida1)
      );
      formdata.append(
        'qtdSugerida2',
        parseFloat(dataUpdate.data.promotion.qtdSugerida2)
      );
      formdata.append('produto', product.id);
      formdata.append(
        'tituloPromocao',
        dataUpdate.data.promotion.tituloPromocao
      );
      formdata.append(
        'valorProduto',
        parseFloat(
          dataUpdate.data.promotion.valorProduto
            .replace('R$ ', '')
            .replace(',', '.')
        )
      );
      formdata.append(
        'categoria',
        dataUpdate.data.promotion.categoria === 'NaN' ||
          (isEmpty(dataUpdate.data.promotion.categoria) &&
            !dataUpdate.data.promotion.categoria)
          ? '0'
          : parseInt(dataUpdate.data.promotion.categoria, 10)
      );
      if (
        product.cod !== 'instantaneo' &&
        product.cod !== 'diadasorte' &&
        product.cod !== 'sabadaodasorte' &&
        dataUpdate.data.promotion.modalidade === 'susep'
      ) {
        formdata.append('numeroSerie', dataUpdate.data.promotion.numeroSerie);
      }
      formdata.append(
        'dataReferencia',
        formatDate(dataReferencia, 'yyyy-MM-dd')
      );
      formdata.append('modalidade', dataUpdate.data.promotion.modalidade);

      formdata.append('codigoProduto', product.cod);

      if (product.cod === 'instantaneo') {
        formdata.append('instantanea', 1);
        const { file } = dataUpdate.data;
        formdata.append('file', file);
      }
      formdata.append(
        'idInstituicao',
        parseInt(dataUpdate.data.promotion.idInstituicao, 10)
      );
      if (dataUpdate.data.promotion.idCgSusep) {
        formdata.append(
          'idCgSusep',
          parseInt(dataUpdate.data.promotion.idCgSusep, 10)
        );
      }
      if (product.cod !== 'instantaneo') {
        formdata.append(
          'dataSorteioPrincipal',
          `${formatDate(dataReferencia, 'yyyy-MM-dd')} ${
            dataUpdate.data.promotion.horarioSorteio
          }`
        );
      }
      const copyDataVigencia = { ...dataVigencia };

      if (
        typeof copyDataVigencia.inicial === 'string' &&
        isValid(parseISO(copyDataVigencia.inicial))
      )
        copyDataVigencia.inicial = parseISO(copyDataVigencia.inicial);

      if (
        typeof copyDataVigencia.final === 'string' &&
        isValid(parseISO(copyDataVigencia.final))
      )
        copyDataVigencia.final = parseISO(copyDataVigencia.final);

      const {
        inicial: dataVigenciaInicio,
        final: dataVigenciaFim,
      } = copyDataVigencia;

      if (dataVigenciaInicio && isValid(dataVigenciaInicio)) {
        formdata.append(
          'dataVigenciaInicio',
          formatDate(dataVigenciaInicio, 'yyyy-MM-dd HH:mm:ss')
        );
      }
      if (dataVigenciaFim && isValid(dataVigenciaFim)) {
        formdata.append(
          'dataVigenciaFim',
          formatDate(dataVigenciaFim, 'yyyy-MM-dd HH:mm:ss')
        );
      }

      formdata.append('extracao', extracao);

      formdata.append('config', JSON.stringify(config));

      setLoading(true);
      const verb = draft && draft._id ? 'put' : 'post';
      if (
        (hasPermission('create_promotion_draft') && verb === 'post') ||
        (hasPermission('update_promotion_draft') && verb === 'put')
      ) {
        const { data } = await api[verb](
          `/promotions/drafts/${(draft && draft._id) || ''}`,
          formdata
        );
        if (data) {
          const promotion = get(data, 'data.promotion', {});

          if (!extracao) setExtracao(promotion.extracao || '');

          set(data, 'data.promotion', {
            ...promotion,
            dataReferencia: getDateFormat(promotion.dataReferencia),
            dataVigenciaInicio: format(
              parseISO(promotion.dataVigenciaInicio),
              'dd/MM/yyyy HH:mm:ss'
            ),
            dataVigenciaFim: format(
              parseISO(promotion.dataVigenciaFim),
              'dd/MM/yyyy HH:mm:ss'
            ),
          });

          updateDraft(data);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  function getTimeFormat(data) {
    const newDate = parseISO(data);
    return format(newDate, 'HH:mm');
  }

  useEffect(() => {
    if (!draft._id) getConfig();
    else {
      let d = { ...draft };
      if (draft && draft.data) {
        const promotion = get(d, 'data.promotion', {});

        const dataVigenciaInicio = promotion.dataVigenciaInicio
          ? parseDate(promotion.dataVigenciaInicio).toISOString()
          : null;

        const dataVigenciaFim = promotion.dataVigenciaFim
          ? parseDate(promotion.dataVigenciaFim).toISOString()
          : null;

        d = set(d, 'data.promotion', {
          ...promotion,
          horarioSorteio: getTimeFormat(promotion.dataSorteioPrincipal),
          dataVigenciaInicio,
          dataVigenciaFim,
        });
      }

      setInitialData(d);
    }
  }, [draft, getConfig]);

  useEffect(() => {
    setNextStep(
      schema.isValidSync(initialData, {
        abortEarly: true,
        context: { produto: product },
      }) && draft._id
    );
  }, [initialData, draft._id, setNextStep, product]);

  const calculateDataVigencia = useCallback(
    refDate => {
      const {
        horaVigenciaInicial: horaInicial,
        horaVigenciaFinal: horaFinal,
        tempoDuracao,
        utc,
      } = get(currentConfig, 'current.config', {});

      const setTime = (strTime = '', date) => {
        const [hours, minutes, seconds] = strTime.split(':');
        const dateFinal = new Date(
          setHours(
            setMinutes(setSeconds(date, Number(seconds)), Number(minutes)),
            Number(hours)
          )
        );

        let newDate;
        if (utc && utc.length > 0) {
          newDate = utc.startsWith('+')
            ? addHours(dateFinal, utc.substr(1))
            : subHours(dateFinal, utc.substr(1));
        } else {
          newDate = dateFinal;
        }

        return newDate;
      };

      const inicial = setTime(
        horaInicial,
        subDays(startOfDay(refDate), tempoDuracao || 7)
      );
      const final = setTime(horaFinal, subDays(endOfDay(refDate), 1));
      setDataVigencia(() => ({ inicial, final }));
    },
    [setDataVigencia]
  );

  const [calculatingExtraction, setCalculatingExtraction] = useState(false);

  const calculateExtractionNumber = useCallback(() => {
    const referenceDate = isValid(dataReferencia)
      ? format(dataReferencia, 'yyyy-MM-dd')
      : null;
    const productId = product.id || '';

    if (!productId || !referenceDate || calculatingExtraction || extracao)
      return;

    const horarioSorteio = get(
      document.getElementById('data.promotion.horarioSorteio'),
      'value',
      ''
    );

    setCalculatingExtraction(true);
    api
      .get('/promotions/drafts/next-extraction', {
        params: {
          produto: productId,
          data_referencia: `${referenceDate}T${horarioSorteio}Z`,
          ignore: draft._id,
        },
      })
      .then(res => {
        const extraction = get(res, 'data.extraction', '');
        setExtracao(extraction);
      })
      .finally(() => {
        setCalculatingExtraction(false);
      });
  }, [product, dataReferencia, extracao, draft, calculatingExtraction]);

  useEffect(() => {
    if (dataReferencia && isValid(dataReferencia)) calculateExtractionNumber();
  }, [dataReferencia]); // eslint-disable-line

  const handleInstitutionChange = value => {
    document.getElementById('data.promotion.idInstituicao').value =
      value.target.value;
  };

  const handleCgSusepChange = value => {
    document.getElementById('data.promotion.idCgSusep').value =
      value.target.value;
  };

  const renderForm = () => {
    return (
      initialData && (
        <Form
          autoComplete="off"
          autoCapitalize="off"
          noValidate
          onSubmit={handleSubmit}
          onChange={e => validateForm(e)}
          initialData={initialData}
        >
          <Grid container spacing={2}>
            {/* Data Referencia */}
            <Grid item xs={12} md={3}>
              <UnFormDatePicker
                disabled={!config.fields.promotions.dataReferencia}
                label="Data Referência"
                name="data.promotion.dataReferencia"
                margin="normal"
                variant="inline"
                inputVariant="outlined"
                fullWidth
                className="inputLarge"
                value={dataReferencia}
                onChange={date => {
                  setDataReferencia(date);
                  calculateDataVigencia(date);
                  return isValid(date) ? format(date, 'yyyy-MM-dd') : null;
                }}
              />
            </Grid>
            <Grid item xs={6} md={9}>
              <TextField
                disabled={!config.fields.promotions.tituloPromocao}
                className="inputLarge"
                name="data.promotion.tituloPromocao"
                label="Título da Promoção"
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} md={2}>
              <TextField
                disabled={!config.fields.promotions.horarioSorteio}
                InputProps={{
                  inputComponent: TextMaskTime,
                }}
                name="data.promotion.horarioSorteio"
                label="Horário do Sorteio"
                margin="normal"
                variant="outlined"
                id="horarioSorteio"
                fullWidth
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <InputLabel htmlFor="data.promotion.categoria">&nbsp;</InputLabel>
              <Select
                disabled={!config.fields.promotions.categoria}
                name="data.promotion.categoria"
                label="Categoria"
                variant="outlined"
                className="inputLarge"
                options={
                  product.cod === 'instantaneo'
                    ? [{ value: 1, label: 'Simples' }]
                    : [
                        { value: 0, label: 'Sem categoria' },
                        { value: 1, label: 'Simples' },
                        { value: 2, label: 'Dupla' },
                        { value: 3, label: 'Tripla' },
                      ]
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <IDatePicker
                label="Inicio da Vigência"
                name="data.promotion.dataVigenciaInicio"
                format="dd/MM/yyyy HH:mm:ss"
                margin="normal"
                variant="inline"
                inputVariant="outlined"
                value={dataVigencia.inicial}
                onChange={date => {
                  setDataVigencia(oldDataVigencia => ({
                    ...oldDataVigencia,
                    inicial: date,
                  }));
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <IDatePicker
                label="Fim da Vigência"
                name="data.promotion.dataVigenciaFim"
                format="dd/MM/yyyy HH:mm:ss"
                margin="normal"
                variant="inline"
                inputVariant="outlined"
                value={dataVigencia.final}
                onChange={date => {
                  setDataVigencia(oldDataVigencia => ({
                    ...oldDataVigencia,
                    final: date,
                  }));
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <MUITextField
                disabled={!config.fields.promotions.extracao}
                name="data.promotion.extracao"
                label="Número da Extração"
                margin="normal"
                variant="outlined"
                value={extracao}
                onChange={e => {
                  setExtracao(get(e, 'currentTarget.value', ''));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => calculateExtractionNumber()}
                        disabled={!!extracao || !isValid(dataReferencia)}
                      >
                        {!calculatingExtraction && <Icon>calculate</Icon>}
                        {calculatingExtraction && (
                          <CircularProgress size={25} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                disabled={!config.fields.promotions.valor}
                InputProps={{
                  inputComponent: TextMaskCurrency,
                }}
                name="data.promotion.valorProduto"
                label="Valor do título"
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                InputProps={{
                  inputComponent: TextMaskNumber,
                }}
                name="data.promotion.qtdMinimaCupons"
                label="Quantidade mínima"
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                InputProps={{
                  inputComponent: TextMaskNumber,
                }}
                name="data.promotion.qtdMaximaCupons"
                label="Quantidade máxima"
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                InputProps={{
                  inputComponent: TextMaskNumber,
                }}
                name="data.promotion.qtdSugerida1"
                label="Quantidade sugerida 1"
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                InputProps={{
                  inputComponent: TextMaskNumber,
                }}
                name="data.promotion.qtdSugerida2"
                label="Quantidade sugerida 2"
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2} style={{ paddingTop: 25 }}>
              <Select
                disabled={!config.fields.promotions.modalidade}
                name="data.promotion.modalidade"
                variant="outlined"
                label="Modalidade"
                options={[
                  {
                    label: 'Secap',
                    value: 'secap',
                  },
                  {
                    label: 'Susep',
                    value: 'susep',
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CgsSusepAutocomplete
                disabled={!config.fields.promotions.cgSusep}
                produto={product.id}
                onChange={handleCgSusepChange}
                value={get(initialData, 'data.promotion.idCgSusep', null)}
              />
              <TextField
                disabled={!config.fields.promotions.cgSusep}
                InputProps={{
                  style: { display: 'none' },
                }}
                type="text"
                name="data.promotion.idCgSusep"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              {product.cod !== 'instantaneo' &&
                product.cod !== 'diadasorte' &&
                product.cod !== 'sabadaodasorte' && (
                  <TextField
                    name="data.promotion.numeroSerie"
                    variant="outlined"
                    margin="normal"
                    type="number"
                    label="Número Série"
                    onInput={e => {
                      e.target.value = Math.max(0, parseInt(e.target.value, 10))
                        .toString()
                        .slice(0, 3);
                    }}
                  />
                )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InstitutionsAutocomplete
                disabled={!config.fields.promotions.instituicao}
                onChange={handleInstitutionChange}
                value={get(initialData, 'data.promotion.idInstituicao', null)}
              />
              <TextField
                disabled={!config.fields.promotions.instituicao}
                InputProps={{
                  style: { display: 'none' },
                }}
                type="text"
                name="data.promotion.idInstituicao"
              />
            </Grid>
          </Grid>

          {/* Botoes  */}
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} md={8} />
            <Grid item xs={12} md={4} align="right">
              {draft.status !== 'approved' && (
                <AccessControl
                  can={['update_promotion_draft', 'create_promotion_draft']}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    color="primary"
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        <Icon>save</Icon>&nbsp;Salvar
                      </>
                    )}
                  </Button>
                </AccessControl>
              )}
            </Grid>
          </Grid>
        </Form>
      )
    );
  };

  const renderMain = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {product && initialData
            ? renderForm()
            : loading && <CircularProgress />}
        </Grid>
      </Grid>
    );
  };

  return <Content>{renderMain()}</Content>;
}

PromotionDraftForm.defaultProps = {
  draft: {},
};

PromotionDraftForm.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]).isRequired,
  draft: PropTypes.oneOfType([PropTypes.object]),
  updateDraft: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
  config: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
