import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import update from 'immutability-helper';
import api from '~/services/api';
import { hasPermission } from '~/components/AccessControl';
import Card from './Card';
import ItemTypes from './ItemTypes';

const style = {
  width: '100%',
  textAling: 'center',
};

export default function Container({
  raffles,
  draft,
  updateDraft,
  raffleType,
  inserting,
}) {
  const [, drop] = useDrop({ accept: ItemTypes.CARD });
  const [loading, setLoading] = useState(false);
  const nullRef = useRef();
  const [cards, setCards] = useState(raffles);
  const findCard = id => {
    const card = cards.filter(c => c.id === id)[0];
    return {
      card,
      index: cards.indexOf(card),
    };
  };

  useEffect(() => {
    if (cards.length !== raffles.length) {
      setCards(raffles);
    } // eslint-disable-next-line
  }, [inserting]);

  const updateState = useCallback(
    _newCards => {
      const rest = draft.data.sweepstakes.filter(
        r => r.tipoSorteio !== raffleType.id
      );
      const _cards = _newCards.map((c, index) => ({ ...c, ordem: index + 1 }));
      const d = { ...draft };
      d.data.sweepstakes = rest.concat(_cards);
      updateDraft(d);
    },
    [draft, raffleType.id, updateDraft]
  );

  const updateOrder = () => {
    if (hasPermission('sort_promotion_draft_sweepstakes')) {
      setLoading(true);
      const data = cards.map(c => c.id);
      api
        .put(`/promotions/drafts/${draft._id}/reorder-sweepstakes`, data)
        .then(() => {
          updateState(cards);
        })
        .finally(() => setLoading(false));
    }
  };

  const onDrop = () => {
    if (draft.status !== 'approved') updateOrder();
  };

  const onMove = useCallback(
    (id, index) => {
      if (draft.status !== 'approved') {
        const cardIdx = cards.findIndex(currentCard => {
          return currentCard.id === id;
        });

        const card = cards[cardIdx] ? { ...cards[cardIdx] } : null;

        if (card) {
          setCards(() => {
            return update(cards, {
              $splice: [
                [cardIdx, 1],
                [index, 0, card],
              ],
            });
          });
        }
      }
    },
    [cards, draft.status]
  );

  const onDelete = useCallback(
    id => {
      setCards(cards.filter(c => c.id !== id));
      updateState(cards.filter(c => c.id !== id));
    },
    [cards, updateState]
  );

  return (
    <>
      {loading && <CircularProgress />}
      <div ref={loading ? nullRef : drop} style={style}>
        {cards &&
          cards.map(card => (
            <Card
              key={card.id}
              id={card.id}
              raffle={card}
              moveCard={onMove}
              findCard={findCard}
              loading={loading}
              onDrop={onDrop}
              draft={draft}
              onDelete={onDelete}
              raffleType={raffleType}
              updateDraft={updateDraft}
            />
          ))}
      </div>
    </>
  );
}

Container.propTypes = {
  raffles: PropTypes.oneOfType([PropTypes.array]).isRequired,
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateDraft: PropTypes.func.isRequired,
  raffleType: PropTypes.oneOfType([PropTypes.object]).isRequired,
  inserting: PropTypes.bool.isRequired,
};
