import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { FaEllipsisV, FaTrash, FaClone } from 'react-icons/fa';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// eslint-disable-next-line react/prop-types
const PromotionMenu = ({ data: draft, onDelete, onClone }) => {
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const confirm = useConfirm();
  const toggleMenu = React.useCallback(
    evt => {
      setMenuAnchor(oldAnchor => {
        if (oldAnchor) return null;
        return get(evt, 'currentTarget', null);
      });
    },
    [setMenuAnchor]
  );

  const handleDelete = React.useCallback(async () => {
    try {
      await confirm({
        title: 'Tem certeza que deseja excluir esta promoção?',
        description:
          'Você está prestes a exluir uma promoção, isto inclui todos recursos relacionados a ela, como: Banners, Sorteios e Regulamento. Você deseja continuar?',
        dialogProps: { fullWidth: true, maxWidth: 'xs' },
        cancellationText: 'Não, cancelar!',
        cancellationButtonProps: { color: 'secondary' },
        confirmationText: 'Sim, continuar!',
        confirmationButtonProps: { color: 'primary' },
      });
      onDelete(draft);
      toggleMenu(); // close with delete promotion.
    } catch (err) {
      toggleMenu(); // close without any action.
    }
  }, [draft, toggleMenu, confirm, onDelete]);

  const handleClone = React.useCallback(async () => {
    try {
      await confirm({
        title: 'Você tem certeza que deseja clonar esta promoção?',
        description:
          'Você está prestes a clonar uma promoção, isto inclui todos recursos relacionados a ela, como: Banners, Sorteios e Regulamento. Você deseja continuar?',
        dialogProps: { fullWidth: true, maxWidth: 'xs' },
        cancellationText: 'Não, cancelar!',
        cancellationButtonProps: { color: 'secondary' },
        confirmationText: 'Sim, continuar!',
        confirmationButtonProps: { color: 'primary' },
      });
      onClone(draft);
      toggleMenu(); // close with clone promotion.
    } catch (err) {
      toggleMenu(); // close without any action.
    }
  }, [draft, toggleMenu, confirm, onClone]);

  return (
    <>
      <IconButton
        size="small"
        style={{ color: '#FFFFFF' }}
        onClick={toggleMenu}
      >
        <FaEllipsisV size={18} style={{ margin: 0 }} />
      </IconButton>
      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={toggleMenu}
      >
        <MenuItem style={{ color: '#444' }} onClick={handleClone}>
          <FaClone size={16} style={{ marginRight: 5 }} /> Clonar
        </MenuItem>

        <MenuItem style={{ color: '#444' }} onClick={handleDelete}>
          <FaTrash size={16} style={{ marginRight: 5 }} /> Excluir
        </MenuItem>
      </Menu>
    </>
  );
};

PromotionMenu.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['approved', 'deleted', 'pending', 'draft'])
      .isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
  onClone: PropTypes.func,
};

PromotionMenu.defaultProps = {
  onDelete: () => {},
  onClone: () => {},
};

export default PromotionMenu;
