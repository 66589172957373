/* eslint-disable react/prop-types */
import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { MdEdit, MdFeedback, MdDelete } from 'react-icons/md';
import {
  Button,
  Fade,
  Popper,
  Switch,
  FormGroup,
  FormControlLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core';

// -- context

// -- services
import history from '~/services/history';
import qs from '~/services/qs';
import api from '~/services/api';

// -- components
import { hasPermission } from '~/components/AccessControl';

import { formatNumberTicket } from '~/helpers/number';
import {
  WinnerContainer,
  WinnerAvatar,
  WinnerContainerContent,
  WinnerActions,
  WinnerData,
} from './styles';
import { Dezena, Paper } from '../../Coupons/styles';
import { PromotionContext } from './context';

export default function SweepstakeWinner({ data: winner, updateWinnerList }) {
  const { promotion } = useContext(PromotionContext);

  // -- permissions
  const canEditPerson = useMemo(
    () => hasPermission('update_person', { toast: false }),
    []
  );
  const canCreateFeedback = useMemo(
    () => hasPermission('create_winner_feedback', { toast: false }),
    []
  );
  const canDeleteWinner = useMemo(
    () => hasPermission('delete_winner', { toast: false }),
    []
  );

  const {
    avatar,
    nome,
    numeroContemplado,
    provisorio,
    cidade,
    bairro,
    idGanhador,
    sorteio,
    numeroExtra,
    tituloPromocao,
    idGanhadorProvisorio,
  } = winner;

  const statusGanhador = get(winner, 'statusGanhador');

  const avatarUrl =
    avatar ||
    `https://ui-avatars.com/api/?size=80&name=${winner.nome ||
      tituloPromocao?.pessoa?.nome}`;

  function handleEditPerson() {
    const editRoute = provisorio
      ? `/person/${idGanhadorProvisorio}/provisional`
      : `/person/${tituloPromocao.idPessoa}`;

    if (provisorio) {
      window.modalProvisionalPerson = { updateWinnerList };
      history.push(editRoute, {
        modal: true,
        onSave: 'updateWinnerList',
        autoClose: true,
      });
    } else {
      window.open(editRoute);
    }
  }

  function handleCreateNewFeedback() {
    const { idPromocao: promocao } = promotion;

    const search = qs.build({
      promocao,
      pessoa: idGanhadorProvisorio || tituloPromocao.pessoa.id,
      provisorio: provisorio === true ? 1 : 0,
    });

    history.push({
      search,
      pathname: '/new-feedback',
      state: { modal: true },
    });
  }

  function getNumeroContemplado(numero = numeroContemplado, pair = false) {
    return formatNumberTicket(numero, pair);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedTitulo, setSelectedTitulo] = useState(null);
  const [placement, setPlacement] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [marked, setMarked] = useState(statusGanhador);

  const handleStatusChange = checked => {
    setMarked(checked);

    setLoading(true);

    api
      .put(`/winner-status/${idGanhador}/${checked ? 1 : 0}`)

      .finally(() => setLoading(false));
  };

  const handleDeleteWinner = () => {
    setLoadingDelete(true);

    api
      .delete(`/winners/${idGanhador}`)
      .then(res => {
        if (res && res.data) {
          setDeleted(true);
        }
      })
      .finally(() => setLoadingDelete(false));
  };

  const handleToggleDezenas = (newPlacement, titulo) => event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => titulo !== selectedTitulo || !prev);
    setSelectedTitulo(titulo);
    setPlacement(newPlacement);
  };

  const renderPopper = () => {
    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        disablePortal
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'undefined',
          },
        }}
        popperOptions={{ positionFixed: true }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              {selectedTitulo &&
                selectedTitulo.dezenas &&
                selectedTitulo.dezenas.map(dezena => (
                  <Dezena key={dezena}>{dezena}</Dezena>
                ))}
            </Paper>
          </Fade>
        )}
      </Popper>
    );
  };

  const renderStatusLabel = () => {
    if (loading) {
      return <CircularProgress size={20} />;
    }

    return marked ? (
      <Typography color="primary">&nbsp;Ativo</Typography>
    ) : (
      <Typography color="secondary">&nbsp;Inativo</Typography>
    );
  };

  const renderStatus = () => {
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              value={statusGanhador}
              defaultChecked={statusGanhador}
              onChange={(_, checked) => handleStatusChange(checked)}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label={renderStatusLabel()}
        />
      </FormGroup>
    );
  };

  return (
    !deleted && (
      <WinnerContainer>
        {selectedTitulo ? renderPopper() : ''}
        <WinnerAvatar url={avatarUrl} />

        <WinnerContainerContent>
          <WinnerData>
            <span>
              Nº do Titulo{' '}
              <button
                type="button"
                onMouseOver={handleToggleDezenas('bottom-start', winner)}
                onFocus={handleToggleDezenas('bottom-start', winner)}
                onMouseLeave={() => setOpen(false)}
                className="titulo"
              >
                <strong>{getNumeroContemplado()}</strong>
              </button>
            </span>
            {sorteio &&
              sorteio.tipo &&
              sorteio.tipo.codigo &&
              sorteio.tipo.codigo === 'numeroextra' && (
                <span>
                  Nº Extra&nbsp;
                  <strong>{getNumeroContemplado(numeroExtra, true)}</strong>
                </span>
              )}

            <span>
              {tituloPromocao === null
                ? nome
                : tituloPromocao.pessoa.nomeSocial ??
                  tituloPromocao.pessoa.nome}
            </span>

            <span>
              {tituloPromocao === null ? cidade : tituloPromocao.pessoa.cidade}{' '}
              |{' '}
              {tituloPromocao === null ? bairro : tituloPromocao.pessoa.bairro}
            </span>
            {renderStatus()}
          </WinnerData>

          <WinnerActions>
            {canDeleteWinner && (
              <Button
                disabled={loadingDelete}
                variant="outlined"
                color="secondary"
                onClick={() => handleDeleteWinner()}
              >
                {loadingDelete ? (
                  <CircularProgress size={20} />
                ) : (
                  <>
                    <MdDelete /> Excluir
                  </>
                )}
              </Button>
            )}

            {canEditPerson && (
              <Button variant="outlined" onClick={() => handleEditPerson()}>
                <MdEdit /> Editar
              </Button>
            )}

            {canCreateFeedback && (
              <Button
                variant="outlined"
                onClick={() => handleCreateNewFeedback()}
              >
                <MdFeedback /> Cadastrar Feedback
              </Button>
            )}
          </WinnerActions>
        </WinnerContainerContent>
      </WinnerContainer>
    )
  );
}

SweepstakeWinner.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
    numeroContemplado: PropTypes.number,
    nome: PropTypes.string,
    provisorio: PropTypes.bool,
    status: PropTypes.bool,
    sorteio: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
  updateWinnerList: PropTypes.func.isRequired,
};
