import styled from 'styled-components';

export const Status = styled.div`
  border-radius: 50px;
  background: ${props => props.backgroundColor};
  color: ${props => props.color};
  padding: 5px 10px;
  max-width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
`;
