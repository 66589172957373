import { get, isNaN, last, toNumber, first } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import update from 'immutability-helper';
import { Form } from '@unform/web';
import { Select, TextField } from '@unform/material-ui';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { Button, MenuItem } from '@material-ui/core';
import toFormdata from 'object-to-formdata';
import AwardsAutocomplete from '~/components/AwardsAutocomplete';
import api from '~/services/api';
import TextMaskCurrency from '~/components/masks/TextMaskCurrency';
import { TextMaskNumber } from '~/components/masks';
import { brlToNumber, decimalFormat } from '~/helpers/number';
import { toast } from 'react-toastify';
import Toast from '~/components/Toast';
import Dialog from './Dialog';
import { useSweepstakeForm } from './SweepstakeFormProvider';
import FieldImage from '../../../../BannersHome/Form/ImageField';
import FieldArchive from '../../../../BannersHome/Form/ArchiveField';
import {
  PreviewContainer,
  Container,
  TwoColumnsField,
} from '../../../../BannersHome/Form/styles';
import { usePromotionContext } from '../../context';

function convertToInteger(str) {
  return parseInt(String(str).replace(/\D+/g, ''), 10) || 0;
}

const SweepstakeFormDialog = () => {
  const { closeForm, data, formOpened } = useSweepstakeForm();
  const [sweepstakeImage, setSweepstakeImage] = useState(null);
  const [changeAwards, setChangeAwards] = useState(false);
  const formRef = useRef();
  const [newSweepstakeImage, setNewSweepstakeImage] = useState(null);
  const [sweepstakeImageAward, setSweepstakeImageAward] = useState(null);
  const [newSweepstakeSheet, setNewSweepstakeSheet] = useState(null);
  const [isSheet, setIsSheet] = useState(false);
  const [drawType, setDrawType] = useState();
  const [classification, setClassification] = useState(0);
  const {
    onSave,
    setOnSave,
    promotion,
    replacePromotion,
    appendSweepstakeTypeFilter,
  } = usePromotionContext();

  const [sweepstakeSpecificList, setSweepstakeSpecificList] = useState([]);

  const [saving, setSaving] = useState(false);

  const [isNextStep, setIsNextStep] = useState(false);
  const [promotionDataStepOne, setPromotionDataStepOne] = useState({});
  const [promotionDataStepTwo, setPromotionDataStepTwo] = useState({});
  const [step, setStep] = useState(0);

  const SweepstakeSpecific = get(promotion, 'produto.id');

  const getSweepstakesSpecific = useCallback(async () => {
    if (SweepstakeSpecific !== undefined) {
      await api
        .get(`/promotions/config/${SweepstakeSpecific}?byProduct=true`)
        .then(res => {
          setSweepstakeSpecificList([...res.data.config.tipoSorteio]);
        });
    }
  }, [SweepstakeSpecific]);

  useEffect(() => {
    getSweepstakesSpecific();
  }, [getSweepstakesSpecific]);

  useEffect(() => {
    // Usado para salvar dados do Form na troca de step
    if (step === 0 && promotionDataStepOne && isNextStep) {
      formRef.current.setFieldValue(
        'description',
        promotionDataStepOne.description
      );
      formRef.current.setFieldValue(
        'serialNumber',
        promotionDataStepOne.serialNumber
      );
      formRef.current.setFieldValue(
        'sugestedValue',
        promotionDataStepOne.sugestedValue
      );
      formRef.current.setFieldValue('order', promotionDataStepOne.order);
    }
    if (step === 1 && promotionDataStepTwo && isNextStep) {
      formRef.current.setFieldValue(
        'planNumber',
        promotionDataStepTwo.planNumber
      );
      formRef.current.setFieldValue(
        'quantityAwards',
        promotionDataStepTwo.quantityAwards
      );
      formRef.current.setFieldValue(
        'deliveryFrequency',
        promotionDataStepTwo.deliveryFrequency
      );
      formRef.current.setFieldValue('order', promotionDataStepTwo.order);
    }
  }, [step, promotionDataStepOne, promotionDataStepTwo]);

  const isNew = useMemo(() => {
    return !get(data, 'idSorteio', null);
  }, [data]);

  const title = useMemo(() => {
    const id = get(data, 'idSorteio', null);
    return isNew ? 'Novo sorteio' : `Editando sorteio #${id}`;
  }, [isNew, data]);

  const sweepstake = useMemo(
    () => ({
      id: get(data, 'idSorteio'),
      image: get(data, 'imagem.urlImagem'),
      description: get(data, 'descricao'),
      sugestedValue: decimalFormat(get(data, 'valorSugerido')),
      type: get(data, 'tipo.idTipoSorteio'),
      order: get(data, 'ordem'),
      status: get(data, 'status'),
      code: get(data, 'codigo'),
      serialNumber: get(data, 'numeroSerie') || null,
      planNumber: get(data, 'numeroPlano') || null,
      quantityAwards: get(data, 'qtdPremiosDisponiveis') || null,
      deliveryFrequency: get(data, 'qtdDesbloqueiaPremio') || null,
      classification: get(data, 'classificacao'),
    }),
    [data]
  );

  useEffect(() => {
    if (!isNew) {
      setClassification(sweepstake.classification);
      setPromotionDataStepOne({
        id: sweepstake.id,
        image: sweepstake.image,
        description: sweepstake.description,
        sugestedValue: sweepstake.sugestedValue,
        type: sweepstake.type,
        order: sweepstake.order,
        status: sweepstake.status,
        code: sweepstake.code,
        serialNumber: sweepstake.serialNumber,
        classification: sweepstake.classification,
      });
      setPromotionDataStepTwo({
        planNumber: sweepstake.planNumber,
        quantityAwards: sweepstake.quantityAwards,
        deliveryFrequency: sweepstake.deliveryFrequency,
      });
      setSweepstakeImageAward(data?.imagemPremio?.urlImagem);
    }
  }, [sweepstake, isNew]);

  useEffect(() => {
    setIsNextStep(false);
    setStep(0);
  }, []);

  const handleClose = useCallback(() => {
    setSweepstakeImage(null);
    setNewSweepstakeImage(null);
    setSweepstakeImageAward(null);
    setChangeAwards(false);
    setStep(0);
    setIsNextStep(false);
    setDrawType(null);
    setClassification(null);
    setPromotionDataStepOne({});
    setPromotionDataStepTwo({});
    setNewSweepstakeSheet(null);
    closeForm();
  }, [closeForm]);

  const handleReturn = useCallback(() => {
    setStep(0);
    const formData = formRef.current.getData();
    setPromotionDataStepTwo(formData);
  }, [formRef]);

  const handleSaveSweepstake = useCallback(
    async formData => {
      const mergedData = {
        ...promotionDataStepOne,
        ...promotionDataStepTwo,
        ...formData,
      };

      const image = newSweepstakeImage || sweepstakeImage;
      const imageAward = sweepstakeImageAward;
      const capitalizingNumbersSheet = newSweepstakeSheet || null;

      if (
        isNextStep &&
        (mergedData.deliveryFrequency === '0' || !mergedData.deliveryFrequency)
      ) {
        return toast.error(
          <Toast
            message="A Frequência de entregas não pode ser zero ou vazia"
            error
          />
        );
      }

      if (isNew && isSheet && !capitalizingNumbersSheet) {
        return toast.error(
          <Toast
            message="Planilha com números da capitalizadora não informada"
            error
          />
        );
      }

      if (
        isNextStep &&
        isSheet &&
        (mergedData.quantityAwards === '0' || !mergedData.quantityAwards)
      ) {
        return toast.error(
          <Toast
            message="A Quantidade de prêmios não pode ser zero ou vazia"
            error
          />
        );
      }

      if (isNextStep && !imageAward) {
        return toast.error(
          <Toast message="A imagem do prêmio é obrigatória" error />
        );
      }

      if (isNextStep && isSheet && !mergedData.classification) {
        return toast.error(
          <Toast message="A classificação é obrigatória" error />
        );
      }

      const cleanedData = Object.fromEntries(
        Object.entries(mergedData).filter(([, value]) => {
          if (isNew) {
            return value !== null && value !== '';
          }
          return value !== null;
        })
      );

      const newData = toFormdata({
        ...cleanedData,
        image,
        imageAward,
        capitalizingNumbersSheet,
        sugestedValue: brlToNumber(mergedData.sugestedValue.replace('R$ ', '')),
        serialNumber: convertToInteger(mergedData.serialNumber),
      });

      setSaving(true);

      const requestUrl = `/promotions/${promotion.idPromocao}/sweepstakes`;

      const request = (...args) =>
        isNew ? api.post(...args) : api.put(...args);
      try {
        const { data: res } = await request(
          isNew ? requestUrl : requestUrl.concat(`/${sweepstake.id}`),
          newData
        );

        if (isNew) {
          const newSweepstakeOrder = get(first(res), 'ordem', 1) - 1;
          const newPromotion = update(promotion, {
            sorteios: { $splice: [[newSweepstakeOrder, 0, first(res)]] },
          });
          const type = get(res, 'tipo.codigo');
          appendSweepstakeTypeFilter(type);
          replacePromotion(newPromotion);
          setOnSave(!onSave);
        } else {
          const allSweepstakes = get(promotion, 'sorteios', []);

          const sweepstakeIndex = allSweepstakes.findIndex(({ idSorteio }) => {
            return idSorteio === get(res, 'idSorteio');
          });
          const newSweepstakeOrder = get(res, 'ordem', 1) - 1;
          const newPromotion = update(promotion, {
            sorteios: {
              $splice: [
                [sweepstakeIndex, 1],
                [newSweepstakeOrder, 0, res],
              ],
            },
          });
          replacePromotion(newPromotion);
        }
        handleClose();
      } catch (err) {
        console.error(err); // eslint-disable-line
      } finally {
        setSaving(false);
      }

      return true;
    },
    [
      onSave,
      setOnSave,
      sweepstakeImage,
      newSweepstakeImage,
      isNew,
      promotion,
      handleClose,
      replacePromotion,
      setSaving,
      sweepstake.id,
      appendSweepstakeTypeFilter,
      isNextStep,
      promotionDataStepOne,
      promotionDataStepTwo,
      newSweepstakeSheet,
      step,
      sweepstakeImageAward,
    ]
  );

  const defineTypeStates = useCallback(type => {
    switch (type) {
      case 6:
        setIsSheet(true);
        setIsNextStep(true);
        break;
      case 8:
        setIsSheet(true);
        setIsNextStep(true);
        break;
      case 9:
        setIsSheet(false);
        setIsNextStep(true);
        break;
      case 10:
        setIsSheet(false);
        setIsNextStep(true);
        break;
      case 12:
        setIsSheet(false);
        setIsNextStep(true);
        break;
      default:
        setIsSheet(false);
        setIsNextStep(false);
    }
  }, []);

  const calculateNextOrder = useCallback(
    evt => {
      const type = get(evt, 'target.value');

      setDrawType(type);

      defineTypeStates(type);

      if (!type || isNaN(toNumber(type))) return;

      const allSweepstakes = get(promotion, 'sorteios', []);

      const lastOfType = { nextOrder: null, nextTypeToFound: type };

      while (lastOfType.nextOrder === null) {
        const allSweepstakeOfType = allSweepstakes.filter(item => {
          const typeId = get(item, 'tipo.idTipoSorteio');
          return typeId === lastOfType.nextTypeToFound;
        });

        const itemFound = last(allSweepstakeOfType);

        if (!itemFound && lastOfType.nextTypeToFound === 1) {
          lastOfType.nextOrder = 0;
          break;
        } else if (!itemFound) {
          lastOfType.nextTypeToFound -= 1;
        } else {
          lastOfType.nextOrder = itemFound.ordem;
          break;
        }
      }

      formRef.current.setFieldValue('order', lastOfType.nextOrder + 1);
    },
    [promotion]
  );

  const updateClassification = useCallback(evt => {
    const classificationType = get(evt, 'target.value');
    setClassification(classificationType);
  }, []);

  useEffect(() => {
    defineTypeStates(sweepstake.type);
  }, [sweepstake]);

  useUpdateEffect(() => {
    if (sweepstake.image) {
      setSweepstakeImage(sweepstake.image);
    }
  }, [sweepstake]);

  if (!formOpened) return null;

  const handleGetFormData = () => {
    setStep(1);
    const formData = formRef.current.getData();
    setPromotionDataStepOne(formData);
  };

  return (
    <Dialog
      title={title}
      onCancel={handleClose}
      onReturn={handleReturn}
      isReturn={step === 1}
      formId="sweepstake-form"
      loading={saving}
      okText="Salvar"
    >
      <Container>
        <>
          <Form
            onSubmit={handleSaveSweepstake}
            initialData={sweepstake}
            ref={formRef}
            id="sweepstake-form"
          >
            {step === 0 && (
              <div style={{ display: 'flex' }}>
                <PreviewContainer>
                  <FieldImage
                    src={
                      newSweepstakeImage &&
                      typeof newSweepstakeImage === 'object'
                        ? URL.createObjectURL(newSweepstakeImage)
                        : sweepstakeImage
                    }
                    onChange={image => {
                      setNewSweepstakeImage(image);
                    }}
                  />
                </PreviewContainer>

                <div style={{ marginLeft: 15, width: '100%' }}>
                  {changeAwards && (
                    <>
                      <AwardsAutocomplete
                        margin="none"
                        valueKey="key"
                        product={get(promotion, 'produto.id')}
                        onChange={evt => {
                          const award = evt.target.value;
                          const image = award.imagemSorteio;
                          setSweepstakeImage(image);
                          setChangeAwards(false);
                          setNewSweepstakeImage(null);
                        }}
                      />
                      <div
                        style={{
                          height: 2,
                          marginTop: 10,
                          marginBottom: 15,
                          borderBottom: '1px dotted rgba(0, 0, 0, 0.4)',
                        }}
                      />
                    </>
                  )}
                  <TextField
                    name="description"
                    label="Descrição"
                    fullWidth
                    variant="outlined"
                  />
                  <TextField
                    style={{ marginTop: 15 }}
                    name="serialNumber"
                    label="Número Série"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      inputComponent: TextMaskNumber,
                    }}
                  />
                  <Button
                    size="small"
                    variant="text"
                    color="primary"
                    style={{ marginBottom: 15 }}
                    onClick={() => setChangeAwards(oldState => !oldState)}
                    disableRipple
                    disableFocusRipple
                  >
                    {!sweepstake.description
                      ? 'Selecionar prêmio'
                      : 'Trocar prêmio'}
                  </Button>

                  <TextField
                    name="sugestedValue"
                    label="Valor sugerido"
                    variant="outlined"
                    style={{ marginBottom: 15 }}
                    InputProps={{
                      inputComponent: TextMaskCurrency,
                    }}
                    disabled={!isNew && isNextStep}
                  />
                  <TwoColumnsField>
                    <Select
                      name="type"
                      label="Tipo do Sorteio"
                      variant="outlined"
                      readOnly={!isNew && !!sweepstake.id}
                      disabled={!isNew && !!sweepstake.id}
                      value={drawType}
                      onChange={calculateNextOrder}
                    >
                      {sweepstakeSpecificList.map(type => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.descricao}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField name="order" label="Ordem" variant="outlined" />
                  </TwoColumnsField>
                  {promotion.instantanea && (
                    <Select
                      name="classification"
                      label="Classificação"
                      variant="outlined"
                      value={
                        classification ? String(classification) : classification
                      }
                      onChange={updateClassification}
                    >
                      <MenuItem value="0">Comum</MenuItem>
                      <MenuItem value="1">Especial</MenuItem>
                      <MenuItem value="2">Acumulado</MenuItem>
                    </Select>
                  )}
                  {isNextStep && (
                    <Button
                      size="small"
                      variant="text"
                      color="primary"
                      style={{ marginBottom: 15 }}
                      onClick={handleGetFormData}
                      disableRipple
                      disableFocusRipple
                    >
                      Editar detalhes
                    </Button>
                  )}
                </div>
              </div>
            )}
            {step === 1 && (
              <div style={{ display: 'flex' }}>
                <PreviewContainer>
                  <FieldImage
                    isBalloon
                    src={
                      sweepstakeImageAward &&
                      typeof sweepstakeImageAward === 'object'
                        ? URL.createObjectURL(sweepstakeImageAward)
                        : sweepstakeImageAward
                    }
                    onChange={image => {
                      setSweepstakeImageAward(image);
                    }}
                  />
                </PreviewContainer>
                <div style={{ marginLeft: 15, width: '100%' }}>
                  <TextField
                    name="planNumber"
                    label="Número plano"
                    fullWidth
                    variant="outlined"
                    type="number"
                  />
                  <TextField
                    style={{ marginTop: 15 }}
                    name="quantityAwards"
                    label="Quantidade de prêmios"
                    fullWidth
                    variant="outlined"
                    type="number"
                    disabled={!isNew}
                  />
                  <TextField
                    style={{ marginTop: 15 }}
                    name="deliveryFrequency"
                    label="Frequência de entrega"
                    fullWidth
                    variant="outlined"
                    type="number"
                  />
                  {isSheet && isNew && (
                    <>
                      <div style={{ display: 'flex', marginTop: 15 }}>
                        <FieldArchive
                          src={newSweepstakeSheet}
                          onChange={e => {
                            setNewSweepstakeSheet(e);
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </Form>
        </>
      </Container>
    </Dialog>
  );
};

export default SweepstakeFormDialog;
