import React, { useRef, useState } from 'react';
import { Popover, IconButton } from '@material-ui/core';
import { MdInsertEmoticon } from 'react-icons/md';
import PropTypes from 'prop-types';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
// import { Container } from './styles';

export default function EmojiPopover({ onPick }) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        transitionDuration={200}
      >
        <Picker
          showPreview={false}
          darkMode={false}
          showSkinTones={false}
          onSelect={(...args) => {
            setOpen(false);
            onPick(...args);
          }}
          title=""
          emoji=""
        />
      </Popover>
      <IconButton ref={ref} onClick={() => setOpen(!open)} edge="end">
        <MdInsertEmoticon />
      </IconButton>
    </>
  );
}

EmojiPopover.defaultProps = {
  onPick: () => {},
};

EmojiPopover.propTypes = {
  onPick: PropTypes.func,
};
