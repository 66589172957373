import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';

import { MdAdd } from 'react-icons/md';
import useAwards from './useAwards';
import AccessControl, { hasPermission } from '../AccessControl';

function AwardsAutocomplete({
  onChange,
  getOption,
  valueKey,
  label,
  value: defaultValue,
  onClickAdd,
  margin,
  product,
}) {
  const { awards, loading } = useAwards({ product });
  const [value, setValue] = useState(null);
  const rendered = useRef(false);
  useEffect(() => {
    // call on change
    if (
      !!value &&
      onChange &&
      typeof onChange === 'function' &&
      rendered.current
    ) {
      onChange({ target: { value: value && value[valueKey] } });
    }

    // empty value
    if (!value && rendered.current) {
      onChange({ target: { value: null } });
    }

    // -- set rendered
    if (!rendered.current) rendered.current = true;
  }, [value]); // eslint-disable-line

  useEffect(() => {
    if (defaultValue) {
      const stringValue = String(defaultValue);
      const selected =
        awards.find(p => String(p[valueKey]) === stringValue) || null;
      if (selected !== undefined) setValue(selected);
    }
  }, [awards, defaultValue, valueKey]);

  return (
    <Autocomplete
      options={awards}
      loading={loading}
      getOptionLabel={getOption}
      value={value}
      onChange={(_, selectValue) => setValue(selectValue)}
      renderOption={option => (
        <>
          <img src={option.url} alt={option.titulo} width="50" />
          &nbsp;{option.titulo} <i>(R$ {option.valor})</i>
        </>
      )}
      renderInput={params => (
        <TextField
          variant="outlined"
          margin={margin}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            style: {
              ...params.InputProps.style,
              paddingRight: 'inherit',
            },
            endAdornment: (
              <AccessControl can="create_sweepstakes_award">
                <InputAdornment position="end">
                  {hasPermission('create_award', { toast: false }) && (
                    <IconButton
                      aria-label="Novo Prêmio"
                      onClick={onClickAdd}
                      onMouseDown={onClickAdd}
                    >
                      <MdAdd />
                    </IconButton>
                  )}
                </InputAdornment>
              </AccessControl>
            ),
          }}
        />
      )}
    />
  );
}

AwardsAutocomplete.defaultProps = {
  onChange: () => {},
  getOption: opt => opt.titulo,
  valueKey: 'id',
  value: undefined,
  label: 'Selecione o Prêmio',
  onClickAdd: () => {},
  margin: 'normal',
  product: undefined,
};

AwardsAutocomplete.propTypes = {
  onChange: PropTypes.func,
  getOption: PropTypes.func,
  valueKey: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.string,
  onClickAdd: PropTypes.func,
  margin: PropTypes.string,
  product: PropTypes.number,
};

export default memo(AwardsAutocomplete);
