import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDrop } from 'react-dnd';
import api from '~/services/api';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { isEmpty } from 'lodash';
import ItemTypes from './itemTypes';
import CardEbook from './Card';

export default function ListEbooks({ draft, refetch, setRefetch }) {
  const [loading, setLoading] = useState(false);
  const [ebooks, setEbooks] = useState([]);

  const [, drop] = useDrop({ accept: ItemTypes.CARD });
  const nullRef = useRef();

  const findAllDraftEbooks = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/promotions/drafts/${draft._id}/ebook`);
      if (!isEmpty(data)) {
        return setEbooks(data);
      }
      return setEbooks([]);
    } finally {
      setLoading(false);
    }
  }, [refetch]);

  useEffect(() => {
    findAllDraftEbooks();
  }, [refetch, findAllDraftEbooks]);

  const findCard = id => {
    const card = ebooks.filter(c => c._id === id)[0];
    return {
      card,
      index: ebooks.indexOf(card),
    };
  };

  const onMove = useCallback(
    (id, index) => {
      if (draft.status !== 'approved') {
        const cardIdx = ebooks.findIndex(currentCard => {
          return currentCard._id === id;
        });

        const card = ebooks[cardIdx] ? { ...ebooks[cardIdx] } : null;

        if (card) {
          setEbooks(() => {
            return update(ebooks, {
              $splice: [
                [cardIdx, 1],
                [index, 0, card],
              ],
            });
          });
        }
      }
    },
    [ebooks, draft.status]
  );

  const updateOrder = async () => {
    try {
      setLoading(true);
      const data = ebooks.map(c => c._id);
      await api.put(`/promotions/drafts/${draft._id}/reorder-ebooks`, data);
    } finally {
      setLoading(false);
    }
  };

  const onDrop = () => {
    if (draft.status !== 'approved') updateOrder();
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <div
          ref={loading ? nullRef : drop}
          style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {ebooks?.map(el => {
            return (
              <CardEbook
                key={el._id}
                ebook={el}
                draft={draft}
                setRefetch={setRefetch}
                refetch={refetch}
                findCard={findCard}
                moveCard={onMove}
                onDrop={onDrop}
              />
            );
          })}
        </div>
      )}
    </>
  );
}

ListEbooks.propTypes = {
  draft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setRefetch: PropTypes.func.isRequired,
  refetch: PropTypes.bool.isRequired,
};
